/** @jsxImportSource @emotion/react */
import React from 'react';

import styled from '@emotion/styled';

import Box from "@common/components/Box";
import {IMenuItem} from "@common/types/interfaces/IMenuItem";
import {Menu} from "@common/components/Menu/Menu";
import Footer from "@common/components/Footer";


interface PerspectiveLayoutProps {
    isShow: boolean;
    list: IMenuItem[];
    active?: number;
    children?: React.ReactNode;
}

const PerspectiveLayoutWrapper = styled(Box)`
    width: 100%;
    height: 100%;
    position: fixed;
    perspective: 1500px;
`;

const ActiveLayout = styled(Box)<{ active: boolean }>`
    width: 100%;
    height: 100%;
    position: relative;
    transform-origin: 0 50%;
    transition: transform 0.4s;
    transform: ${({active}) =>
            active
                    ? 'translateZ(-1800px) translateX(-50%) rotateY(45deg)'
                    : 'translateZ(0) translateX(0) rotateY(0deg)'};
`;

const PerspectiveLayout: React.FC<PerspectiveLayoutProps> = ({isShow, children, list, active}) => {
    return (
        <PerspectiveLayoutWrapper>
            <ActiveLayout active={isShow}>
                {children}
            </ActiveLayout>
            {isShow && <Menu isShow={isShow} list={list} active={active}/>}
            {isShow && <Footer/>}
        </PerspectiveLayoutWrapper>
    );
};

export default PerspectiveLayout;
