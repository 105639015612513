/** @jsxImportSource @emotion/react */
import React from 'react';

import Box from "@common/components/Box";
import OnlineStreamersBlockItem from "@modules/online/components/onlineStreamersBlock/OnlineStreamersBlockItem";
import Carousel from "@common/components/Carousel/Carousel";
import Skeleton from "@common/components/Skeleton";

interface OnlineStreamersBlockProps {
    list?: {
        user_name: string;
        avatar: string;
        viewer_count: number;
        game_name: string;
    }[];
    isLoading?: boolean;
    onChangeStream: (user_name: string) => void;
}

const OnlineStreamersBlock: React.FC<OnlineStreamersBlockProps> = ({list, isLoading = false, onChangeStream}) => {
    const renderContent = () => {
        if (isLoading) {
            return (
                <Carousel width="100%" itemWidth="310px" disableButton>
                    {Array.from({length: 5}).map((_, idx) => <Skeleton height="70px" width="100%"/>)}
                </Carousel>
            )
        }

        if (!list) {
            return null;
        }

        return (
            <Carousel width="100%" itemWidth="310px" disableButton>
                {list.map((item) => <OnlineStreamersBlockItem {...item} key={`streamer-block-${item.user_name}`}
                                                              onClick={() => onChangeStream(item.user_name)}/>)}
            </Carousel>
        );
    };

    return (
        <Box display="flex" width="100%" alignItems="center" justifyContent="center" gap="16px">
            {renderContent()}
        </Box>
    );
};

export default OnlineStreamersBlock;
