/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

interface ImageProps {
    src: string;
    alt: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: string;
    boxShadow?: string;
    objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
    margin?: string;
}

const Image = styled.img<ImageProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  border: ${({ border }) => border || 'none'};
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  margin: ${({ margin }) => margin || '0'};
`;

export default Image;
