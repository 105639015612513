/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Card from "@common/components/Card";
import {Contact} from "@common/types/models/contact.model";
import Box from "@common/components/Box";
import Text from "@common/components/Text";
import Image from "@common/components/Image";
import {getFollowersText} from "@modules/streamers/services/getFollowersText";
import ContactsBlock from "@common/components/ContactsBlock";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import Icon from "@common/components/Icon";
import {useNavigate} from "react-router-dom";

interface StreamersCard {
    index?: number;
    name: string;
    login: string;
    followers: number;
    avatar: string;
    contacts: Contact[];
}

const StreamersCard: React.FC<StreamersCard> = ({index = 0, name, login, avatar, followers, contacts}) => {
    const theme = useTheme().theme as Themes;
    const navigate = useNavigate();
    const animationDelay = index * 0.1; // Задержка для каждого элемента

    const stylesCard = css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        opacity: 0;
        animation: comeIn ease 0.5s forwards;
        animation-delay: ${animationDelay}s;
        cursor: pointer;
    `;

    const stylesIconBlock = css`
        position: absolute;
        right: 0;
        top: 0;
    `;

    const handleOpenStreamerDetail = () => navigate(login);

    return (
        <Card customCss={stylesCard} width="100%" height="100%" padding="8px">
            <Box className="click_block" display="flex" flexDirection="column" alignItems="center" gap="8px" onClick={handleOpenStreamerDetail}>
                <Text fontSize="17px" fontWeight="600">{name}</Text>
                <Image src={avatar} alt={`Avatar streamer ${login}`} borderRadius="100%" width="80%" height="80%"/>
                <Text color={theme.colors.text.secondary} fontSize="13px" fontWeight="500">{getFollowersText(followers)}</Text>
            </Box>
            <ContactsBlock contacts={contacts} size="24px"/>
            <Box customCss={stylesIconBlock} padding="8px">
                <Icon viewBox="0 -960 960 960" size="19px" fill={theme.colors.text.subtitle}>
                    <path
                        d="M202.87-111.87q-37.78 0-64.39-26.61t-26.61-64.39v-554.26q0-37.78 26.61-64.39t64.39-26.61H480v91H202.87v554.26h554.26V-480h91v277.13q0 37.78-26.61 64.39t-64.39 26.61H202.87ZM395.41-332 332-395.41l361.72-361.72H560v-91h288.13V-560h-91v-133.72L395.41-332Z"/>
                </Icon>
            </Box>
        </Card>
    );
};

export default StreamersCard;
