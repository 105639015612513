/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";
import {useNavigate} from "react-router-dom";

import Text from "@common/components/Text";
import Button from "@common/components/Button";
import Box from "@common/components/Box";
import Icon from "@common/components/Icon";
import {mediaQueries} from "@styles/media";

const MainJoinButton: React.FC = () => {
    const navigate = useNavigate();

    const handlerNavigate = () => navigate("/join");

    const stylesText = css`
        ${mediaQueries.mobile} {
            font-size: 12px;
        }
    `;

    const stylesButton = css`
        ${mediaQueries.mobile} {
            height: 36px;
        }
    `;

    const stylesIcon = css`
        ${mediaQueries.mobile} {
            height: 16px;
            width: 16px;
        }
    `;

    return (
        <Button customCss={stylesButton} height="40px" onClick={handlerNavigate}>
            <Box display="flex" alignItems="center" gap="6px">
                <Text customCss={stylesText} fontSize="14px" fontWeight="700" lineHeight="14px">Вступить</Text>
                <Icon customCss={stylesIcon} size="18px">
                    <path d="m605-183.67-58.67-58.66 196-196H59.67v-84h682.66l-196-196L605-777l296.33 297L605-183.67Z"/>
                </Icon>
            </Box>
        </Button>
    );
};

export default MainJoinButton;
