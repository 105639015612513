import React from "react";

import Box from "@common/components/Box";
import CategoryHeader from "@common/components/CategoryContainer/CategoryHeader";

interface CategoryContainerProps {
    title: string;
    sizeTitle?: string;
    rightSide?: React.ReactNode;
    children?: React.ReactNode;
}

const CategoryContainer: React.FC<CategoryContainerProps> = ({title, sizeTitle, rightSide, children}) => {
    return (
        <Box display="flex" flexDirection="column" gap="16px" width="100%">
            <CategoryHeader title={title} sizeTitle={sizeTitle} rightSide={rightSide}/>
            {children}
        </Box>
    );
};

export default CategoryContainer;