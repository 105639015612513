/** @jsxImportSource @emotion/react */
import React from "react";

import {css} from "@emotion/react";
import styled from "@emotion/styled";

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import {IStatistic} from "@modules/streamers/types/interfaces/IStatistic";
import {statisticElementList} from "@modules/streamers/services/data/StatisticElementList";
import {statisticItemArrowList} from "@modules/streamers/services/data/StatisticItemArrowList";
import Icon from "@common/components/Icon";
import {StatisticItemArrowType} from "@modules/streamers/types/enums/StatisticItemArrowType";
import {checkStringProcent} from "@modules/streamers/components/StreamerStatisticBlock/helpers/checkStringProcent";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";

interface StreamerStatisticBlockElementProps {
    value: IStatistic;
}

const HorizontalLine = styled.hr<{ color: string }>`
  width: 100%;
  border: 0;
  border-top: 1px solid ${({color}) => color};
  margin: 4px 0;
  box-shadow: 0 -8px 18px 1px ${({color}) => color};
`;

const StreamerStatisticBlockElement: React.FC<StreamerStatisticBlockElementProps> = ({value}) => {
    const {value: statistic, type} = value;
    const theme = useTheme().theme as Themes;
    const isMobile = useMediaQuery(mediaQueries.mobile);
    const statisticInfo = statisticElementList[type];
    const statisticArrow = statistic.arrow !== undefined ? statisticItemArrowList[statistic.arrow] : null;

    if (!statistic.value) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Text fontSize="12px" color={theme.colors.text.subtitle}>
                {checkStringProcent(statistic.procent)}
            </Text>
            <Box display="flex" alignItems="center" gap="4px">
                <Text fontSize={isMobile ? "18px" : "20px"} fontWeight="600">{statistic.value}</Text>
                {statisticArrow && statistic.arrow !== StatisticItemArrowType.Null && (
                    <Icon viewBox={statisticArrow.viewBox} size={isMobile ? "18px" : "20px"} fill={statisticArrow.color}>
                        {statisticArrow.icon}
                    </Icon>
                )}
            </Box>
            <HorizontalLine color={statisticInfo.color} />
            <Text customCss={css`text-transform: uppercase;`} fontSize={isMobile ? "10px" : "11px"} color={statisticInfo.color} textAlign="right">
                {statisticInfo.name}
            </Text>
        </Box>
    );
};

export default StreamerStatisticBlockElement;
