/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {mediaQueries} from "@styles/media";

const styles = {
    aboutContainer: css`
        flex: 1;
    `,

    aboutBlock: css`
        ${mediaQueries.mobile} {
            flex-direction: column;
        }
    `,

    aboutBlockLeft: css`
        ${mediaQueries.tablet} {
            width: 50%
        }

        ${mediaQueries.mobile} {
            width: 100%;
        }
    `,
    aboutBlockRight: css`
        ${mediaQueries.tablet} {
            width: 50%
        }

        ${mediaQueries.mobile} {
            width: 100%;
        }
    `,
};

export default styles;