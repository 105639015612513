/** @jsxImportSource @emotion/react */
import React from 'react';

import styled from "@emotion/styled";

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import jsolLogo from "@assets/img/jsol-logo.png";
import { useTheme } from "@styles/theme/ThemeContext";
import { Themes } from "@styles/theme/themes";

const FooterWrapper = styled(Box)`
    position: absolute;
    bottom: 0;
    padding: 16px;
`;

const JSOLLogo = styled.img`
    height: 24px;
    width: 24px;
    margin-left: 8px;
`;

const Footer: React.FC = () => {
    const theme = useTheme().theme as Themes;

    const handlerNavigate = () => {
        window.open("https://github.com/jsolteam", "_blank");
    };

    return (
        <FooterWrapper width="100%" display="flex" justifyContent="flex-end">
            <Box className="click_block" display="flex" alignItems="center" onClick={handlerNavigate}>
                <Text color={theme.colors.text.subtitle} fontSize="14px" fontWeight="600">Created by JSOL</Text>
                <JSOLLogo src={jsolLogo} alt="JSOL logo" />
            </Box>
        </FooterWrapper>
    );
};

export default Footer;
