/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

interface ContainerProps {
    maxWidth?: string;
    padding?: string;
    margin?: string;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '1440px'};
  padding: ${({ padding }) => padding || '0 16px'};
  margin: ${({ margin }) => margin || '0 auto'};
`;

export default Container;
