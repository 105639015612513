/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';

import Text from "@common/components/Text";

import Box from "@common/components/Box";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import Icon from "@common/components/Icon";

interface OnlineCountViewerProps {
    count: number;
}

const OnlineCountViewer: React.FC<OnlineCountViewerProps> = ({count}) => {
    const theme = useTheme().theme as Themes;

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Icon viewBox="0 -960 960 1100" fill="#EA3323" size="26px">
                <path
                    d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Z"/>
            </Icon>
            <Text color={theme.colors.text.secondary} fontSize="16px" fontWeight="500" lineHeight="18px">{count}</Text>
        </Box>
    );
};

export default OnlineCountViewer;
