import axios from "axios";
import {TwitchClip} from "@modules/streamers/types/models/twitchClip.model";

const apiUrl = `${process.env.REACT_APP_API_URL}/join`;

interface DataJoin {
    twitchLink: string;
    socialLink: string;
    nameAge: string;
    contactLink: string;
}

export const fetchJoinInfo = async (data: DataJoin): Promise<any> => {
    const request = {
        twitch_info: data.twitchLink,
        social_link: data.socialLink,
        name_age: data.nameAge,
        contact_link: data.contactLink
    };
    const {data: dat} = await axios.post(apiUrl, request);
    return dat;
}