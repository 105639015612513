/** @jsxImportSource @emotion/react */
import React from "react";

import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";

import Icon from "@common/components/Icon";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

interface HeaderBackButtonProps {
    size?: string;
    backLink: string;
}

const HeaderBackButtonWrapper = styled.button`
    background: none;
    padding: 0;
    border: none;
`;

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({backLink, size = "20px"}) => {
    const theme = useTheme().theme as Themes;
    const navigate = useNavigate();

    const handlerNavigateHome = () => navigate(backLink);

    return (
        <HeaderBackButtonWrapper className="click_block" onClick={handlerNavigateHome}>
            <Icon size={size} viewBox="0 -960 960 960" fill={theme.colors.text.secondary}>
                <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/>
            </Icon>
        </HeaderBackButtonWrapper>
    );
}