/** @jsxImportSource @emotion/react */
import React from "react";

import styled from '@emotion/styled';
import {css, SerializedStyles} from "@emotion/react";

import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import Box from "@common/components/Box";
import Text from "@common/components/Text";
import OnlineCountViewer from "@modules/online/components/OnlineCountViewer";

export interface VideoTitleProps {
    avatar?: string;
    title: string;
    channel?: string;
    online?: number;
}

const VideoTitle: React.FC<VideoTitleProps> = ({avatar, title, channel, online}) => {
    const theme = useTheme().theme as Themes;

    const stylesText = css`
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;

    return (
        <Box display="flex" gap="8px" width="100%" justifyContent="space-between">
            {avatar && <Box></Box>}
            <Box display="flex" flexDirection="column" gap="4px" width="80%">
                <Text customCss={stylesText} fontSize="16px" fontWeight="700" lineHeight="16px">{title}</Text>
                {channel && <Text customCss={stylesText} color={theme.colors.text.subtitle} fontSize="14px" fontWeight="400" lineHeight="16px">{channel}</Text>}
            </Box>
            {online && <OnlineCountViewer count={online} />}
        </Box>
    );
};

export default VideoTitle;
