/** @jsxImportSource @emotion/react */
import React, { useState, useRef, ReactNode, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from "@common/components/Box";
import CarouselButton from "@common/components/Carousel/CarouselButton";

interface CarouselProps {
    children?: ReactNode[];
    width: string;
    itemWidth: string;
    height?: string;
    disableButton?: boolean;
}

const CarouselContainer = styled.div<{ width: string }>`
    overflow: hidden;
    width: ${props => props.width};
    position: relative;
`;

const CarouselItem = styled.div<{ width: string; height?: string }>`
    flex: 0 0 auto;
    width: ${props => props.width};
    height: ${props => props.height || 'auto'};
`;

const Carousel: React.FC<CarouselProps> = ({ children, width, itemWidth, height, disableButton = false }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const [visibleItemsCount, setVisibleItemsCount] = useState<number>(0);
    const totalItems = React.Children.count(children);

    useEffect(() => {
        const calculateVisibleItems = () => {
            const containerWidth = containerRef.current?.offsetWidth || 0;
            const itemWidthPixels = Math.floor(itemWidth.includes('%') ? containerWidth / 100 * parseFloat(itemWidth) : parseFloat(itemWidth));
            setVisibleItemsCount(Math.floor(containerWidth / itemWidthPixels));
        };

        calculateVisibleItems();
        window.addEventListener('resize', calculateVisibleItems);
        return () => window.removeEventListener('resize', calculateVisibleItems);
    }, [itemWidth, width]);

    useEffect(() => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth || 0;
            const itemWidthPixels = Math.floor(itemWidth.includes('%') ? containerWidth / 100 * parseFloat(itemWidth) : parseFloat(itemWidth));
            let totalOffset = Math.floor((itemWidthPixels + 16) * currentIndex);

            if (currentIndex >= totalItems - visibleItemsCount) {
                totalOffset = Math.floor(containerRef.current.scrollWidth - containerWidth);
            }

            containerRef.current.style.transform = `translateX(-${totalOffset}px)`;
        }
    }, [currentIndex, itemWidth, visibleItemsCount]);

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.deltaY > 0) {
            handleNextClick();
        } else if (event.deltaY < 0) {
            handlePrevClick();
        }
    };

    const handlePrevClick = () => {
        setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const handleNextClick = () => {
        setCurrentIndex(prevIndex => Math.min(prevIndex + 1, totalItems - visibleItemsCount));
    };

    const stylesCarouselInner = css`
      transition: transform 0.3s ease-in-out;
      will-change: transform;
      padding-right: 8px;
    `;

    return (
        <CarouselContainer width={width} onWheel={handleScroll}>
            <Box id="scrollable" customCss={stylesCarouselInner} ref={containerRef} display="flex" gap="16px" width="100%">
                {React.Children.map(children, (child, index) => (
                    <CarouselItem key={index} width={`calc(${itemWidth} - 16px)`} height={height}>
                        {child}
                    </CarouselItem>
                ))}
            </Box>
            {((totalItems > visibleItemsCount) && !disableButton) && (
                <>
                    {currentIndex > 0 && <CarouselButton arrow="left" size="36px" onClick={handlePrevClick} />}
                    {currentIndex < totalItems - visibleItemsCount && <CarouselButton arrow="right" size="36px" onClick={handleNextClick} />}
                </>
            )}
        </CarouselContainer>
    );
};

export default Carousel;
