/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import {mediaQueries} from "@styles/media";

interface StreamerDetailIndicatorOnlineProps {
    isOnline: boolean;
}

const Indicator = styled.div<StreamerDetailIndicatorOnlineProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ isOnline }) => (isOnline ? "red" : "gray")};
  animation: ${({ isOnline }) => (isOnline ? "blink 1s infinite" : "none")};
  
  ${mediaQueries.mobile} {
    width: 6px;
    height: 6px;
  }
`;

const StreamerIndicatorOnline: React.FC<StreamerDetailIndicatorOnlineProps> = ({ isOnline }) => {
    return <Indicator isOnline={isOnline} />;
};

export default StreamerIndicatorOnline;