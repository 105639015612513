import { useState, useEffect } from 'react';

function useMediaQuery(queryKey: string): boolean {
    const mediaQuery = queryKey.replace('@media', '').trim();
    const [matches, setMatches] = useState<boolean>(false);

    useEffect(() => {
        const mediaList = window.matchMedia(mediaQuery);

        const updateMatch = () => setMatches(mediaList.matches);
        updateMatch();

        mediaList.addEventListener('change', updateMatch);

        return () => {
            mediaList.removeEventListener('change', updateMatch);
        };
    }, [mediaQuery]);

    return matches;
}

export default useMediaQuery;
