/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Card from "@common/components/Card";
import Text from "@common/components/Text";
import List from "@common/components/List/List";
import {IList} from "@common/types/interfaces/IList";
import ErrorData from "@common/components/ErrorData";

interface AboutInfoCardProps {
    description: string;
    members: number;
    created: string;
}

const AboutInfoCard: React.FC<AboutInfoCardProps> = ({description, members, created}) => {
    const listInfo: IList[] = [
        {
            name: "Количество участников",
            value: `${members} человек(а)`
        },
        {
            name: "Дата основания",
            value: created
        }
    ];

    const stylesCard = css`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `;

    return (
        <Card customCss={stylesCard}>
            {description ? <>
                    <Text fontSize="14px" fontWeight="500" lineHeight="1.6">{description}</Text>
                    <List list={listInfo} width="50%"/>
                </> :
                <ErrorData/>
            }
        </Card>
    );
};

export default AboutInfoCard;
