/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import slmImage from "@assets/img/logo.png";
import Image from "@common/components/Image";
import {mediaQueries} from "@styles/media";
import Box from "@common/components/Box";

const AboutSLMLogo: React.FC = () => {
    const stylesBox = css`
        position: relative;
        
        &:before {
            content: '';
            position: absolute;
            top: 53%;
            left: 50%;
            width: 60%;
            aspect-ratio: 1/1;
            background-color: #007b3b;
            border: 2px solid rgb(8, 96, 53);
            opacity: .35;
            border-radius: 50%;
            transform: translate(-52%, -52%);
            z-index: -1;
        }
    `;

    const stylesImage = css`
        aspect-ratio: 1/1;
        z-index: 1;
    `;

    return (
        <Box customCss={stylesBox} display="flex" justifyContent="center" alignItems="center" width="100%">
            <Image css={stylesImage} src={slmImage} alt="SLM Squad Logo" width="80%" />
        </Box>
    );
};

export default AboutSLMLogo;
