import {IStatisticItemArrowRecord} from "@modules/streamers/types/interfaces/IStatisticItemArrow";
import {StatisticItemArrowType} from "@modules/streamers/types/enums/StatisticItemArrowType";

export const statisticItemArrowList: IStatisticItemArrowRecord = {
    [StatisticItemArrowType.UP]: {
        icon: (<path
            d="M117.93-217.98q-12.67-12.43-13.29-29.2-.62-16.78 12.06-29.69L319.54-479q24.35-24.35 58.66-24.35 34.3 0 58.65 24.35l93.35 93.11 200.04-199.52h-72.87q-17.45 0-29.48-11.97-12.02-11.96-12.02-29.32 0-17.37 12.02-29.54 12.03-12.17 29.48-12.17h173.26q17.45 0 29.48 12.02 12.02 12.02 12.02 29.48v173.02q0 17.45-11.96 29.47-11.97 12.03-29.33 12.03t-29.54-11.74q-12.17-11.73-12.17-28.76v-73.87L588.85-326.48q-24.35 24.35-58.65 24.35-34.31 0-58.66-24.35l-93.34-94.11-201.61 202.61q-12.68 12.68-29.33 12.68t-29.33-12.68Z"/>),
        viewBox: "0 -960 960 960",
        color: "#5D8A5D"
    },
    [StatisticItemArrowType.DOWN]: {
        icon: (<path
            d="M733.24-323.83 537.2-518.35 441.85-423q-26.35 26.35-64.65 26.35-38.31 0-64.66-26.35L103.7-633.61q-12.68-12.91-13.06-31.56-.38-18.66 13.29-32.09 12.68-12.67 31.83-12.67t31.83 12.67L377.2-487.65 472.54-583q26.35-26.35 64.66-26.35 38.3 0 64.65 26.35l195.28 195.52v-55.87q0-19.15 13.17-32.32 13.18-13.18 32.33-13.18t32.33 13.18q13.17 13.17 13.17 32.32v165.02q0 19.16-13.17 32.33-13.18 13.17-32.33 13.17H677.37q-19.15 0-32.33-13.17-13.17-13.17-13.17-32.33 0-19.15 13.17-32.32 13.18-13.18 32.33-13.18h55.87Z"/>),
        viewBox: "0 -960 960 960",
        color: "#A56060"
    },
    [StatisticItemArrowType.Null]: {
        icon: <></>,
        viewBox: "",
        color: ""
    }
};