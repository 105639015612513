/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { useModal } from '@common/hooks/useModal';
import {ModalButton} from "@common/components/Modal/ModalButton";

const ModalOverlay = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    overflow: hidden;
    animation: ${props => props.isOpen ? 'fadeIn' : 'fadeOut'} 0.3s ease;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: inherit;
        backdrop-filter: blur(10px);
        z-index: -1;
    }
`;

const ModalContent = styled.div<{ isOpen: boolean }>`
    position: relative;
    z-index: 1;
    transform: scale(${props => props.isOpen ? 1 : 0.9});
    opacity: ${props => props.isOpen ? 1 : 0};
    transition: opacity 0.3s ease, transform 0.3s ease;
`;

const ModalTitle = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const Modal: React.FC = () => {
    const { isOpen, title, content, closeModal } = useModal();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setShowModal(true);
        } else {
            const timer = setTimeout(() => setShowModal(false), 300); // Match animation duration
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    if (!showModal) return null;

    return (
        <ModalOverlay isOpen={isOpen} onClick={closeModal}>
            <ModalContent isOpen={isOpen} onClick={e => e.stopPropagation()}>
                {title && <ModalTitle>{title}</ModalTitle>}
                {content}
            </ModalContent>
            <ModalButton action={closeModal} />
        </ModalOverlay>
    );
};

export default Modal;
