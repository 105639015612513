import { MenuType } from "@common/types/enums/MenuType";
import { IMenuItem } from "@common/types/interfaces/IMenuItem";
import { menuList } from "@common/services/data/menuList";
import {Streamer} from "@modules/streamers/types/models/streamer.model";

export const getMenuList = (type: MenuType, streamers?: Streamer[]): IMenuItem[] => {
    if (type === MenuType.STREAMERS && streamers) {
        const strem = streamers.map((streamer, index) => ({
            id: index,
            name: streamer.name,
            link: `/streamers/${streamer.login}`,
        }));
        return strem;
    }

    return menuList;
};
