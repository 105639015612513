/** @jsxImportSource @emotion/react */
import React from "react";

import {css} from "@emotion/react";

import Box from "@common/components/Box";
import Icon from "@common/components/Icon";

interface ModalButtonProps {
    action: () => void;
}

export const ModalButton: React.FC<ModalButtonProps> = ({action}) => {
    const stylesButton = css`
        position: absolute;
        top: 16px;
        right: 16px;
    `;

    return (
        <Box customCss={stylesButton} className="click_block" onClick={action}>
            <Icon size="38px" viewBox="0 -960 960 960">
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
            </Icon>
        </Box>
    );
}