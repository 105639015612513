/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {useTheme} from '@styles/theme/ThemeContext';
import Box from "@common/components/Box";
import styled from "@emotion/styled";
import {replacePlaceholders} from "@common/services/replacePlaceholdersVideo";
import Chip from "@common/components/Chip";
import Text from "@common/components/Text";
import {getViewCountText} from "@common/components/Video/helpers/getViewCountText";
import {getCreatedDateText} from "@common/services/getCreatedDateText";
import {replaceTimeSeparators} from "@common/components/Video/helpers/replaceTimeSeparators";
import VideoTitle, {VideoTitleProps} from "@common/components/Video/VideoTitle";
import {useModal} from "@common/hooks/useModal";
import VideoModal from "@common/components/Video/VideoModal";
import VideoIFrame from "@common/components/Video/VideoIFrame";

interface VideoProps {
    width?: string;
    thumbnail?: string;
    video_id: string;
    view?: number;
    created?: string;
    duration?: string;
    information?: VideoTitleProps;
    visible?: boolean;
    customCss?: SerializedStyles;
}

const VideoThumbnail = styled.img`
  width: 99%;
  aspect-ratio: 16/9;
  border-radius: 8px;
`;

const Video: React.FC<VideoProps> = ({
                                         width,
                                         video_id,
                                         thumbnail,
                                         view,
                                         created,
                                         duration,
                                         information,
                                         visible = false,
                                         customCss
                                     }) => {
    const {openModal} = useModal();
    const {theme} = useTheme();
    const thumbnailUrl = replacePlaceholders(thumbnail || "", 640, 360);

    const handleOpenModal = () => openModal(undefined, <VideoModal video_id={video_id}/>);

    const stylesVideo = css`
      aspect-ratio: 16/9;
      position: relative;

      ${!visible && `&:hover {
        scale: .98;
      }`}
    `;

    const stylesThumbnail = css`
      background-color: ${theme.colors.card};
      box-shadow: 0 0 6px 0 ${theme.colors.shadow};
    `;

    const stylesOverlay = css`
      position: absolute;
      top: 0;
      padding: 8px;
    `;

    return (
        <Box customCss={customCss} display="flex" flexDirection="column" width={width} gap="8px">
            <Box className={!visible ? "click_block" : ""} customCss={stylesVideo} width="100%" borderRadius="8px"
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
                 onClick={!visible ? handleOpenModal : undefined}
            >
                {visible ? <VideoIFrame type="channel" src={video_id}/>
                    : <VideoThumbnail css={stylesThumbnail} src={thumbnailUrl} width="100%"/>
                }
                <Box customCss={stylesOverlay} display="flex" flexDirection="column" justifyContent="space-between"
                     width="100%" height="100%">
                    <Box display="flex" width="100%">
                        {duration && <Chip><Text fontSize="14px" fontWeight="600"
                                                 lineHeight="14px">{replaceTimeSeparators(duration)}</Text></Chip>}
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        {view && <Chip><Text fontSize="14px" fontWeight="600"
                                             lineHeight="14px">{getViewCountText(view)}</Text></Chip>}
                        {created && <Chip><Text fontSize="14px" fontWeight="600"
                                                lineHeight="14px">{getCreatedDateText(created)}</Text></Chip>}
                    </Box>
                </Box>
            </Box>
            {information && <VideoTitle {...information} />}
        </Box>
    );
};

export default Video;
