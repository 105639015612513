/** @jsxImportSource @emotion/react */
import React from "react";
import {useQuery} from "@tanstack/react-query";
import styles from "./styles";
import PageContainer from "@common/components/PageContainer";
import Box from "@common/components/Box";
import PageTitle from "@common/components/PageTitle";
import AboutSLMLogo from "@modules/about/components/AboutSLMLogo";
import {fetchAbout} from "@modules/about/services/fetchAbout";
import {AboutModel} from "@modules/about/types/models/about.model";
import ContactsBlock from "@common/components/ContactsBlock";
import AboutInfoCard from "@modules/about/components/AboutInfoCard";
import Skeleton from "@common/components/Skeleton";
import AboutLastStream from "@modules/about/components/AboutLastStream";
import AboutStatisticBlock from "@modules/about/components/aboutStatisticBlock/AboutStatisticBlock";

const AboutPage: React.FC = () => {
    const {data: aboutData, isLoading: aboutIsLoading} = useQuery<AboutModel, Error>({
        queryKey: ["about-info"],
        queryFn: fetchAbout
    });

    return (
        <PageContainer>
            <PageTitle name="О нас"/>
            <Box customCss={styles.aboutContainer} display="flex" flexDirection="column" width="100%" gap="64px">
                <Box customCss={styles.aboutBlock} display="flex" width="100%" gap="32px">
                    <Box customCss={styles.aboutBlockLeft} display="flex" flexDirection="column" width="30%">
                        <AboutSLMLogo/>
                        {aboutIsLoading ? (
                            <Skeleton height="30px"/>
                        ) : (
                            <ContactsBlock contacts={aboutData?.contacts || []} size="30px"/>
                        )}
                    </Box>
                    <Box customCss={styles.aboutBlockRight} width="70%">
                        {aboutIsLoading ? (
                            <Skeleton height="200px"/>
                        ) : (
                            <AboutInfoCard
                                description={aboutData?.description || ""}
                                members={aboutData?.members || 0}
                                created={aboutData?.created || ""}
                            />
                        )}
                    </Box>
                </Box>
                <AboutLastStream />
                <AboutStatisticBlock />
            </Box>
        </PageContainer>
    );
};

export default AboutPage;
