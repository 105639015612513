/** @jsxImportSource @emotion/react */
import React, {ReactNode} from 'react';

import styled from '@emotion/styled';

import Text from '@common/components/Text';
import {Themes} from '@styles/theme/themes';

interface TabsTextLinkProps {
    isActive: boolean;
    fontSize: string;
    onClick?: () => void;
    children: ReactNode;
}

const TabsTextLinkWrapper = styled.span<{ isActive: boolean; fontSize: string }>`
  font-size: ${({fontSize}) => fontSize};
  line-height: ${({fontSize}) => fontSize};
  color: ${({isActive, theme}) => isActive
          ? (theme as Themes).colors.text.secondary
          : (theme as Themes).colors.text.subtitle};
  font-weight: ${({isActive}) => (isActive ? 600 : 400)};
  cursor: pointer;
`;

const TabsTextLink: React.FC<TabsTextLinkProps> = ({isActive, fontSize, onClick, children}) => {
    return (
        <TabsTextLinkWrapper className={isActive ? "" : "click_block"} isActive={isActive} fontSize={fontSize} onClick={onClick}>
            {children}
        </TabsTextLinkWrapper>
    );
};

export default TabsTextLink;
