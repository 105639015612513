import React from "react";

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import {css} from "@emotion/react";
import {mediaQueries} from "@styles/media";

interface CategoryHeaderProps {
    title: string;
    sizeTitle?: string;
    rightSide?: React.ReactNode;
}

const CategoryHeader: React.FC<CategoryHeaderProps> = ({title, sizeTitle, rightSide}) => {
    const stylesTitle = css`
        ${mediaQueries.mobile} {
            font-size: 18px;
        }
    `;
    
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Text customCss={stylesTitle} fontSize={sizeTitle ?? "20px"} lineHeight={sizeTitle ?? "20px"} fontWeight="600">{title}</Text>
            {rightSide}
        </Box>
    );
};

export default CategoryHeader;