export const getViewCountText = (count: number): string => {
    const remainder10 = count % 10;
    const remainder100 = count % 100;

    let word = "просмотр";

    if (remainder10 === 1 && remainder100 !== 11) {
        word = "просмотр";
    } else if ([2, 3, 4].includes(remainder10) && ![12, 13, 14].includes(remainder100)) {
        word = "просмотра";
    } else {
        word = "просмотров";
    }

    return `${count} ${word}`;
}