/** @jsxImportSource @emotion/react */
import React from "react";

import styles from "./styles";
import PageContainer from "@common/components/PageContainer";
import Box from "@common/components/Box";
import MainLogoTitle from "@modules/main/components/MainLogoTitle";
import MainJoinButton from "@modules/main/components/MainJoinButton";
import MainSLMImage from "@modules/main/components/MainSLMImage";

const MainPage: React.FC = () => {

    return (
        <PageContainer overflow="hidden" fullSize>
            <Box customCss={styles.slmBlock} width="100%" height="100%" display="flex" alignItems="center">
                <Box customCss={styles.leftSide} display="flex" flexDirection="column" gap="24px">
                    <MainLogoTitle />
                    <MainJoinButton />
                </Box>
                <MainSLMImage />
            </Box>
        </PageContainer>
    );
};

export default MainPage;