/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Box from "@common/components/Box";
import Card from "@common/components/Card";
import Image from "@common/components/Image";
import Text from "@common/components/Text";
import Icon from "@common/components/Icon";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import OnlineCountViewer from "@modules/online/components/OnlineCountViewer";

interface OnlineStreamersBlockItemProps {
    user_name: string;
    avatar: string;
    viewer_count: number;
    game_name: string;
    onClick?: () => void;
}

const OnlineStreamersItemBlock: React.FC<OnlineStreamersBlockItemProps> = ({user_name, avatar, game_name, viewer_count, onClick}) => {
    const theme = useTheme().theme as Themes;

    const stylesItem = css`
        flex: 1;
    `;

    return (
        <Card padding="8px">
            <Box className="click_block" display="flex" alignItems="center" gap="8px" onClick={onClick}>
                <Box customCss={stylesItem} display="flex" alignItems="center" gap="8px">
                    <Image src={avatar} alt={`Avatar of ${user_name}`} borderRadius="100%" height="46px" width="46px" />
                    <Box display="flex" flexDirection="column">
                        <Text fontSize="18px" fontWeight="600">{user_name}</Text>
                        <Text color={theme.colors.text.subtitle} fontSize="10px" fontWeight="400">Стримит: {game_name}</Text>
                    </Box>
                </Box>
                <OnlineCountViewer count={viewer_count} />
            </Box>
        </Card>
    );
};

export default OnlineStreamersItemBlock;
