import React from "react";

import {css} from "@emotion/react";

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import Icon from "@common/components/Icon";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import {mediaQueries} from "@styles/media";

const ErrorData: React.FC = () => {
    const theme = useTheme().theme as Themes;

    const stylesText = css`
        ${mediaQueries.mobileAndTablet} {
            font-size: 16px;
        }
    `;

    return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" gap="16px">
            <Icon fill={theme.colors.text.secondary} viewBox="0 0 103.696 103.696" size="50px">
                <path
                    d="M57.676,40.489c0-4.797,3.904-8.701,8.703-8.701c4.797,0,8.701,3.903,8.701,8.701c0,1.381-1.119,2.5-2.5,2.5   s-2.5-1.119-2.5-2.5c0-2.041-1.66-3.701-3.701-3.701s-3.703,1.66-3.703,3.701c0,1.381-1.119,2.5-2.5,2.5   S57.676,41.869,57.676,40.489z M29.954,40.462c0-4.797,3.904-8.701,8.703-8.701c4.797,0,8.701,3.903,8.701,8.701   c0,1.381-1.119,2.5-2.5,2.5c-1.381,0-2.5-1.119-2.5-2.5c0-2.041-1.66-3.701-3.701-3.701c-2.042,0-3.703,1.66-3.703,3.701   c0,1.381-1.119,2.5-2.5,2.5S29.954,41.843,29.954,40.462z M29.845,72.893c3.71-8.932,12.764-14.703,23.064-14.703   c10.084,0,19.084,5.742,22.927,14.63c0.658,1.521-0.041,3.286-1.563,3.944c-1.52,0.66-3.284-0.043-3.942-1.563   c-2.894-6.688-9.731-11.011-17.421-11.011c-7.868,0-14.747,4.318-17.523,11.004c-0.479,1.154-1.596,1.85-2.771,1.85   c-0.384,0-0.773-0.074-1.15-0.23C29.934,76.18,29.208,74.424,29.845,72.893z M85.467,103.696H18.23   C8.179,103.696,0,95.518,0,85.467V18.23C0,8.178,8.179,0,18.23,0h67.235c10.053,0,18.23,8.178,18.23,18.23v67.235   C103.697,95.518,95.518,103.696,85.467,103.696z M18.23,8.579c-5.321,0-9.651,4.33-9.651,9.651v67.235   c0,5.321,4.33,9.651,9.651,9.651h67.235c5.321,0,9.651-4.33,9.651-9.651V18.23c0-5.321-4.33-9.651-9.651-9.651H18.23z"/>
            </Icon>
            <Text customCss={stylesText} color={theme.colors.text.subtitle} fontSize="20px" fontWeight="600">Не удалось загрузить</Text>
        </Box>
    );
};

export default ErrorData;