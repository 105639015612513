/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";
import { useTheme } from "@styles/theme/ThemeContext";
import { Themes } from "@styles/theme/themes";
import useMediaQuery from "@common/hooks/useMediaQuery";
import { mediaQueries } from "@styles/media";
import Card from "@common/components/Card";
import CategoryContainer from "@common/components/CategoryContainer/CategoryContainer";
import Box from "@common/components/Box";
import { css } from "@emotion/react";
import Skeleton from "@common/components/Skeleton";
import {keepPreviousData, useQuery, useQueryClient} from "@tanstack/react-query";
import { TwitchClip } from "@modules/streamers/types/models/twitchClip.model";
import { fetchStreamerClips } from "@modules/streamers/services/fetchStreamerClips";
import StreamerClipsVideo from "@modules/streamers/components/StreamerClipsBlock/StreamerClipsVideo";
import Button from "@common/components/Button";
import Text from "@common/components/Text";
import {ITwitchClip} from "@modules/streamers/types/interfaces/ITwitchClip";

interface StreamerClipsBlockProps {
    isLoading?: boolean;
    login: string | undefined;
}

const StreamerClipsBlock: React.FC<StreamerClipsBlockProps> = ({ isLoading, login = "" }) => {
    const theme = useTheme().theme as Themes;
    const isMobile = useMediaQuery(mediaQueries.mobile);
    const queryClient = useQueryClient();

    const [clips, setClips] = useState<ITwitchClip[]>([]);
    const [cursor, setCursor] = useState<string>("");

    const {
        data: streamerClips,
        isLoading: isLoadingStreamerClips,
        error: errorStreamerClips
    } = useQuery<TwitchClip, Error>({
        queryKey: [`streamer-clips-${login}`, cursor],
        queryFn: () => fetchStreamerClips(login, cursor),
        placeholderData: keepPreviousData,
    });

    useEffect(() => {
        if (streamerClips) {
            setClips(prevState => [...prevState, ...streamerClips.data])
        }
    }, [streamerClips]);

    const handleShowMore = () => {
        if (streamerClips && streamerClips.data.length === 8)
            setCursor(streamerClips.cursor)
    };

    const renderSkeleton = () => (
        <>
            {[...Array(8)].map((_, idx) => (
                <Skeleton key={idx} height="140px"/>
            ))}
        </>
    );

    useEffect(() => {

    }, [login, cursor]);

    const stylesGrid = css`
      display: grid;
      grid-template-columns: ${isMobile ? "repeat(2, calc(50% - 8px))" : "repeat(4, calc(25% - 12px))"};
      gap: 16px;
    `;

    return (
        <CategoryContainer title="Лучшие клипы" sizeTitle="18px">
            <Card>
                <Box customCss={stylesGrid}>
                    {isLoadingStreamerClips ? renderSkeleton() :
                        clips.map((clip) => (
                            <StreamerClipsVideo
                                key={clip.id}
                                video_id={clip.embed_url}
                                thumbnail={clip.thumbnail_url}
                                created={clip.created_at}
                                duration={clip.duration.toString()}
                                title={clip.title}
                                view={clip.view_count}
                                creater={clip.creator_name}
                            />
                        ))
                    }
                </Box>
            </Card>
            {!isLoadingStreamerClips && streamerClips?.data.length === 8 && (
                <Button
                    onClick={handleShowMore}
                    backgroundColor={theme.colors.card}
                    width="100%"
                    padding="10px"
                >
                    <Text fontSize="14px" fontWeight="600" lineHeight="14px">Показать еще</Text>
                </Button>
            )}
        </CategoryContainer>
    );
};

export default StreamerClipsBlock;
