import { Statistic } from "@modules/streamers/types/models/statistic.model";
import { StatisticWeek } from "@modules/streamers/types/models/statisticWeek.model";
import { IStatistic } from "@modules/streamers/types/interfaces/IStatistic";
import { StatisticType } from "@modules/streamers/types/enums/StatisticType";

export const converterStatisticType = (statistic: Statistic | StatisticWeek): IStatistic[] => {
    const commonStatistics: IStatistic[] = [];

    const isStatistic = 'followers_gained' in statistic;

    if (isStatistic) {
        commonStatistics.push({ type: StatisticType.FOLLOWER_GAINED, value: statistic.followers_gained });
    }

    const statisticCommon = isStatistic ? statistic.statistic : statistic;

    commonStatistics.push(
        { type: StatisticType.HOURS_STREAMED, value: statisticCommon.hours_streamed },
        { type: StatisticType.AVG_VIEWERS, value: statisticCommon.avg_viewers },
        { type: StatisticType.PEAK_VIEWERS, value: statisticCommon.peak_viewers },
        { type: StatisticType.HOURS_WATCHED, value: statisticCommon.hours_watched },
        { type: StatisticType.COUNT_STREAMS, value: statisticCommon.count_streams }
    );

    return commonStatistics;
};