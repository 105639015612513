import {IBioChipRecord} from "@modules/streamers/types/interfaces/IBioChip";
import {BioType} from "@modules/streamers/types/enums/BioType";

export const bioChipList: IBioChipRecord = {
    [BioType.FIRST_NAME]: {
        icon: (<path d="M714.61-450.77q12.77 0 21.39-8.62 8.61-8.61 8.61-21.38T736-502.15q-8.62-8.62-21.39-8.62H593.08q-12.77 0-21.39 8.62-8.61 8.61-8.61 21.38t8.61 21.38q8.62 8.62 21.39 8.62h121.53Zm0-115.38q12.77 0 21.39-8.62 8.61-8.61 8.61-21.38T736-617.54q-8.62-8.61-21.39-8.61H593.08q-12.77 0-21.39 8.61-8.61 8.62-8.61 21.39 0 12.77 8.61 21.38 8.62 8.62 21.39 8.62h121.53Zm-353.07 132.3q-34.46 0-60.39 5.54-25.92 5.54-46 17.62-18.3 10.69-27.76 23.92-9.47 13.23-9.47 28.38 0 10.47 8.04 17.5 8.04 7.04 19.89 7.04h231.38q11.85 0 19.88-7.54 8.04-7.53 8.04-18.61 0-13.54-9.27-26.27t-27.96-24.42q-20.07-12.08-46-17.62-25.92-5.54-60.38-5.54Zm0-52.3q29.15 0 49.57-20.43 20.43-20.42 20.43-49.57 0-29.16-20.43-49.58-20.42-20.42-49.57-20.42t-49.58 20.42q-20.42 20.42-20.42 49.58 0 29.15 20.42 49.57 20.43 20.43 49.58 20.43ZM172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31Zm0-60h615.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM160-240v-480 480Z"/>),
        viewBox: "0 -960 960 960",
        name: "Имя"
    },
    [BioType.LAST_NAME]: {
        icon: (<path d="M714.61-450.77q12.77 0 21.39-8.62 8.61-8.61 8.61-21.38T736-502.15q-8.62-8.62-21.39-8.62H593.08q-12.77 0-21.39 8.62-8.61 8.61-8.61 21.38t8.61 21.38q8.62 8.62 21.39 8.62h121.53Zm0-115.38q12.77 0 21.39-8.62 8.61-8.61 8.61-21.38T736-617.54q-8.62-8.61-21.39-8.61H593.08q-12.77 0-21.39 8.61-8.61 8.62-8.61 21.39 0 12.77 8.61 21.38 8.62 8.62 21.39 8.62h121.53Zm-353.07 132.3q-34.46 0-60.39 5.54-25.92 5.54-46 17.62-18.3 10.69-27.76 23.92-9.47 13.23-9.47 28.38 0 10.47 8.04 17.5 8.04 7.04 19.89 7.04h231.38q11.85 0 19.88-7.54 8.04-7.53 8.04-18.61 0-13.54-9.27-26.27t-27.96-24.42q-20.07-12.08-46-17.62-25.92-5.54-60.38-5.54Zm0-52.3q29.15 0 49.57-20.43 20.43-20.42 20.43-49.57 0-29.16-20.43-49.58-20.42-20.42-49.57-20.42t-49.58 20.42q-20.42 20.42-20.42 49.58 0 29.15 20.42 49.57 20.43 20.43 49.58 20.43ZM172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31Zm0-60h615.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v455.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM160-240v-480 480Z"/>),
        viewBox: "0 -960 960 960",
        name: "Фамилия"
    },
    [BioType.BIRTHDAY]: {
        icon: (<path d="m210.85-212 282-100-182-182-100 282Zm682.84-457q-7.08 7.46-16.58 7.46-9.5 0-16.57-7.07l-2.62-2.62q-16.31-16.31-39.61-16.31-23.31 0-39.62 16.31L576.07-468.61q-7.07 7.07-16.57 7.26-9.5.2-16.96-7.26-7.46-7.47-7.46-16.77 0-9.31 7.46-16.77l202.61-202.62q30.08-30.07 72.77-30.07t72.77 30.07l3 3q7.08 7.08 7.08 16.39 0 9.3-7.08 16.38ZM395.15-784q7.46-7.46 16.77-7.46 9.31 0 16.77 7.46l8.46 8.46q32 32 32 76.77 0 44.77-32 76.77l-7.23 7.23q-7.07 7.08-16.57 7.27-9.5.19-16.97-7.27-7.46-7.46-7.46-16.77 0-9.3 7.46-16.77l7.24-7.23q17.84-17.84 17.84-43.23 0-25.38-17.84-43.23l-8.47-8.46q-7.07-7.08-7.27-16.58-.19-9.5 7.27-16.96Zm154.31-69.23q7.46-7.46 16.77-7.46 9.31 0 16.77 7.46l42.61 42.62q30.47 30.46 30.47 73.15 0 42.69-30.47 73.15L503-541.69q-7.08 7.08-16.58 7.27-9.5.19-16.96-7.27-7.46-7.46-7.46-16.77 0-9.31 7.46-16.77l122.62-122.61q16.3-16.31 16.3-39.62t-16.3-39.62l-42.62-42.61q-7.08-7.08-7.27-16.58-.19-9.5 7.27-16.96Zm284.61 457.69q-7.46 7.46-16.77 7.46-9.3 0-16.76-7.46l-31.08-31.07q-18.23-18.24-44.62-18.24-26.38 0-44.61 18.24l-31.08 31.07q-7.08 7.08-16.58 7.27-9.5.19-16.96-7.27-7.46-7.46-7.46-16.77 0-9.3 7.46-16.77l31.08-31.07q32.38-32.39 78.15-32.39T803-460.15l31.07 31.07q7.08 7.08 7.27 16.58.19 9.5-7.27 16.96ZM210.85-212Zm-73.62 26.61 131-364.22q4.62-11.85 13.96-18.08 9.35-6.23 20.2-6.23 7.23 0 13.46 2.42 6.23 2.43 11.84 8.04l234.62 234.61q5.61 5.62 8.03 11.85 2.43 6.23 2.43 13.46 0 10.85-6.23 20.19-6.23 9.35-18.08 13.96l-364.23 131q-10.84 4.23-20.88 1.31-10.04-2.92-17.27-10.15t-10.15-17.27q-2.93-10.04 1.3-20.89Z"/>),
        viewBox: "0 -960 960 960",
        name: "Дата рождения"
    },
    [BioType.AGE]: {
        icon: (<path d="M415.08-363.85h127.46l20 59.47q3.23 8.69 9.92 13.46 6.69 4.77 15.39 4.77 14.76 0 22.57-11.85 7.81-11.85 3.35-25l-95.16-271.15q-3.23-8.7-11.11-14.35-7.89-5.65-17.58-5.65h-21q-9.69 0-17.57 5.65-7.89 5.65-11.12 14.35l-96.15 272.38q-4.46 13.15 3.54 24.39 8 11.23 22.15 11.23 8.69 0 15.38-4.97 6.7-4.96 9.93-13.65l20-59.08Zm15.53-46.46 49.01-144.31 48 144.31h-97.01ZM397.69-850q-12.77 0-21.38-8.62-8.62-8.61-8.62-21.38t8.62-21.38q8.61-8.62 21.38-8.62h164.62q12.77 0 21.38 8.62 8.62 8.61 8.62 21.38t-8.62 21.38q-8.61 8.62-21.38 8.62H397.69ZM480-100q-70.15 0-132-26.77-61.85-26.77-108.15-73.08-46.31-46.3-73.08-108.15Q140-369.85 140-440t26.77-132q26.77-61.85 73.08-108.15 46.3-46.31 108.15-73.08Q409.85-780 480-780q60.08 0 115.73 20.39 55.65 20.38 103.35 58.38L727.85-730q8.3-8.31 20.88-8.5 12.58-.19 21.27 8.5t8.69 21.08q0 12.38-8.69 21.07l-28.77 28.77q38 47.7 58.38 103.35Q820-500.08 820-440q0 70.15-26.77 132-26.77 61.85-73.08 108.15-46.3 46.31-108.15 73.08Q550.15-100 480-100Zm0-60q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z"/>),
        viewBox: "0 -960 960 960",
        name: "Возраст"
    },
    [BioType.COUNTRY]: {
        icon: (<path d="M373.85-446.15h110L514-386.23q4.62 9.84 13.62 14.96 9.01 5.12 18.92 5.12h131.15q15.37 0 25.76-10.4 10.4-10.39 10.4-25.76v-155.38q0-15.37-10.4-25.76-10.39-10.4-25.76-10.4h-81.54L566-653.77q-4.62-9.84-13.62-14.96-9.01-5.12-18.92-5.12H362.31q-15.37 0-25.76 10.4-10.4 10.39-10.4 25.76V-270q0 10.33 6.76 17.09 6.75 6.76 17.07 6.76 10.33 0 17.1-6.76 6.77-6.76 6.77-17.09v-176.15Zm179.3 32.3-40-80h-139.3v-132.3h153l40 80h99.3v132.3h-113ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>),
        viewBox: "0 -960 960 960",
        name: "Страна"
    },
    [BioType.CITY]: {
        icon: (<path d="M190.77-136.92q-25.31 0-43.04-17.73Q130-172.39 130-197.69v-418.47q0-25.3 17.73-43.03t43.04-17.73H290v-99.24q0-25.3 17.73-43.03t43.04-17.73h258.46q25.31 0 43.04 17.73T670-776.16v259.24h99.23q25.31 0 43.04 17.73T830-456.16v258.47q0 25.3-17.73 43.04-17.73 17.73-43.04 17.73H530v-160H430v160H190.77Zm-.77-60h100v-100H190v100Zm0-160h100v-100H190v100Zm0-160h100v-100H190v100Zm160 160h100v-100H350v100Zm0-160h100v-100H350v100Zm0-160h100v-100H350v100Zm160 320h100v-100H510v100Zm0-160h100v-100H510v100Zm0-160h100v-100H510v100Zm160 480h100v-100H670v100Zm0-160h100v-100H670v100Z"/>),
        viewBox: "0 -960 960 960",
        name: "Город"
    }
};