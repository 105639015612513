/** @jsxImportSource @emotion/react */
import React from "react";

import styles from "./styles";
import PageContainer from "@common/components/PageContainer";
import Box from "@common/components/Box";
import StreamerBanner from "@modules/streamers/components/StreamerBanner";
import {useParams} from "react-router-dom";
import {StreamerDetail} from "@modules/streamers/types/models/streamerDetail.model";
import {fetchStreamerDetail} from "@modules/streamers/services/fetchStreamerDetail";
import {useQuery} from "@tanstack/react-query";
import {TwitchStream} from "@modules/online/types/models/twitchStream.model";
import {fetchOnline} from "@modules/online/services/fetchOnline";
import StreamerInfoBlock from "@modules/streamers/components/StreamerInfoBlock";
import StreamerStatisticBlock from "@modules/streamers/components/StreamerStatisticBlock/StreamerStatisticBlock";
import StreamerClipsBlock from "@modules/streamers/components/StreamerClipsBlock/StreamerClipsBlock";
import StreamerLastStreamBlock from "@modules/streamers/components/StreamerLastStreamBlock/StreamerLastStreamBlock";
import StreamerGamesBlock from "@modules/streamers/components/StreamerGamesBlock/StreamerGamesBlock";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";

const StreamerDetailPage: React.FC = () => {
    const {name: nameStreamer = ""} = useParams<{ name: string }>();
    const isMobileAndTablet = useMediaQuery(mediaQueries.mobileAndTablet);

    const {
        data: streamerDetail,
        isLoading: isLoadingStreamerDetail,
        error: errorStreamerDetail
    } = useQuery<StreamerDetail, Error>({
        queryKey: [`streamer-detail-${nameStreamer}`],
        queryFn: () => fetchStreamerDetail(nameStreamer)
    });

    const {data: streamsOnline} = useQuery<TwitchStream[], Error>({
        queryKey: ["streams-online"],
        queryFn: fetchOnline,
        staleTime: 1000 * 10,
    });

    const getOnlineStatus = (): boolean => {
        if (streamerDetail && streamsOnline) {
            return !!streamsOnline.find(el => el.user_id === streamerDetail.streamer.id);
        }
        return false;
    };

    return (
        <PageContainer key={nameStreamer}>
            <Box display="flex" flexDirection="column" width="100%">
                <StreamerBanner isLoading={isLoadingStreamerDetail} bannerImg={streamerDetail?.streamer.banner}
                                name={streamerDetail?.streamer.name} login={streamerDetail?.streamer.login}
                                followers={streamerDetail?.streamer.followers}
                                avatar={streamerDetail?.streamer.avatar}
                                contacts={streamerDetail?.streamer.contacts}
                                isOnline={getOnlineStatus()}
                />

                <Box customCss={styles.streamerDetailContent} display="flex" width="100%" gap="32px" justifyContent="space-between">
                    <Box display="flex" flexDirection="column" width={isMobileAndTablet ? "100%" : "76%"} gap="32px">
                        <StreamerInfoBlock isLoading={isLoadingStreamerDetail} description={streamerDetail?.streamer.description} bioList={streamerDetail?.info}/>
                        <StreamerStatisticBlock isLoading={isLoadingStreamerDetail} weekStatistic={streamerDetail?.week_statistic} allStatistic={streamerDetail?.all_statistic}/>
                        <StreamerGamesBlock login={streamerDetail?.streamer.login} />
                        {isMobileAndTablet && <StreamerLastStreamBlock login={streamerDetail?.streamer.login}/>}
                        <StreamerClipsBlock isLoading={isLoadingStreamerDetail} login={streamerDetail?.streamer.login} />
                    </Box>
                    {!isMobileAndTablet && <Box display="flex" flexDirection="column" width="22%" gap="32px">
                        <StreamerLastStreamBlock login={streamerDetail?.streamer.login}/>
                    </Box>}
                </Box>
            </Box>
        </PageContainer>
    );
};

export default StreamerDetailPage;
