/** @jsxImportSource @emotion/react */
import React from 'react';
import {css} from '@emotion/react';
import {useTheme} from '@styles/theme/ThemeContext';
import Box from "@common/components/Box";
import styled from "@emotion/styled";
import {replacePlaceholders} from "@common/services/replacePlaceholdersVideo";
import Chip from "@common/components/Chip";
import Text from "@common/components/Text";
import {getViewCountText} from "@common/components/Video/helpers/getViewCountText";
import {getCreatedDateText} from "@common/services/getCreatedDateText";
import {replaceTimeSeparators} from "@common/components/Video/helpers/replaceTimeSeparators";
import {useModal} from "@common/hooks/useModal";
import VideoModal from "@common/components/Video/VideoModal";
import VideoIFrame from "@common/components/Video/VideoIFrame";
import OnlineCountViewer from "@modules/online/components/OnlineCountViewer";

interface StreamerClipsVideoProps {
    width?: string;
    thumbnail?: string;
    video_id: string;
    view?: number;
    created?: string;
    duration?: string;
    visible?: boolean;
    title?: string;
    creater?: string;
}

const VideoThumbnail = styled.img`
  width: 99%;
  aspect-ratio: 16/9;
  border-radius: 8px;
`;

const StreamerClipsVideo: React.FC<StreamerClipsVideoProps> = ({
                                                                   width,
                                                                   video_id,
                                                                   title,
                                                                   thumbnail,
                                                                   view,
                                                                   created,
                                                                   duration,
                                                                   creater,
                                                                   visible = false,
                                                               }) => {
    const {openModal} = useModal();
    const {theme} = useTheme();
    const thumbnailUrl = replacePlaceholders(thumbnail || "", 640, 360);

    const handleOpenModal = () => openModal(undefined, <VideoModal type="clip" video_id={video_id}/>);

    const stylesVideo = css`
      aspect-ratio: 16/9;
      width: 100%;
      position: relative;

      ${!visible && `&:hover {
        scale: .98;
      }`}
    `;

    const stylesThumbnail = css`
      background-color: ${theme.colors.card};
      box-shadow: 0 0 6px 0 ${theme.colors.shadow};
    `;

    const stylesOverlay = css`
      position: absolute;
      top: 0;
      padding: 6px;
    `;

    const stylesText = css`
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;

    return (
        <Box display="flex" flexDirection="column" width="100%" gap="8px">
            <Box className={!visible ? "click_block" : ""} customCss={stylesVideo} width="100%" borderRadius="8px"
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
                 onClick={!visible ? handleOpenModal : undefined}
            >
                <VideoThumbnail css={stylesThumbnail} src={thumbnailUrl} width="100%"/>
                <Box customCss={stylesOverlay} display="flex" flexDirection="column" justifyContent="space-between"
                     width="100%" height="100%">
                    <Box display="flex" width="100%">
                        {created && <Chip padding="2px"><Text fontSize="10px" fontWeight="600"
                                                lineHeight="10px">{getCreatedDateText(created)}</Text></Chip>}
                    </Box>
                    <Box display="flex" justifyContent="end" width="100%">
                        {view && <Chip padding="2px"><Text fontSize="10px" fontWeight="600"
                                             lineHeight="10px">{getViewCountText(view)}</Text></Chip>}
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" width="100%">
                <Text customCss={stylesText} fontSize="13px" fontWeight="600" lineHeight="13px">{title}</Text>
                <Text customCss={stylesText} fontSize="11px" fontWeight="400" lineHeight="15px"
                      color={theme.colors.text.subtitle}>Автор: <Text fontSize="11px" fontWeight="500" lineHeight="15px"
                                                                      color={theme.colors.text.secondary}>{creater}</Text></Text>
            </Box>
        </Box>
    );
};

export default StreamerClipsVideo;
