import React from "react";

import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {MainLayout} from "@common/components/Layout/MainLayout/MainLayout";
import MainPage from "@pages/MainPage/MainPage";
import AboutPage from "@pages/AboutPage/AboutPage";
import OnlinePage from "@pages/OnlinePage/OnlinePage";
import StreamersPage from "@pages/StreamersPage/StreamersPage";
import StreamerDetailPage from "@pages/StreamerDetailPage/StreamerDetailPage";
import JoinPage from "@pages/JoinPage/JoinPage";


const router = createBrowserRouter([
    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: "/",
                element: <MainPage />
            },
            {
                path: "/about",
                element: <AboutPage />
            },
            {
                path: "/online",
                element: <OnlinePage />
            },
            {
                path: "/streamers/:name",
                element: <StreamerDetailPage />
            },
            {
                path: "/streamers",
                element: <StreamersPage />
            },
            {
                path: "/join",
                element: <JoinPage />
            }
        ]
    }
]);

export const RoutesMain: React.FC = () => {
    return (
        <RouterProvider router={router} />
    );
};