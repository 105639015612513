import React, {useEffect, useState} from 'react';

import {ThemeProvider} from "@styles/theme/ThemeContext";
import GlobalStyles from "@styles/GlobalStyles";
import {RoutesMain} from "@routes/main";
import Box from "@common/components/Box";
import {ModalProvider} from "@common/context/ModalContext";
import Modal from "@common/components/Modal/Modal";
import {StreamersProvider} from "@common/context/StreamersContext";


const App = () => {
    const [isDarkMode, setDarkMode] = useState(true);

    useEffect(() => {
        const updateVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        updateVh(); // Устанавливаем начальный размер
        window.addEventListener('resize', updateVh); // Обновляем размер при изменении окна

        return () => {
            window.removeEventListener('resize', updateVh);
        };
    }, []);

    return (
        <ThemeProvider>
            <StreamersProvider>
                <ModalProvider>
                    <Box style={{width: '100vw', height: 'calc(var(--vh, 1vh) * 100)', overflow: 'hidden'}}>
                        <GlobalStyles/>
                        <RoutesMain/>
                    </Box>
                    <Modal/>
                </ModalProvider>
            </StreamersProvider>
        </ThemeProvider>
    );
}

export default App;
