/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';

import Card from "@common/components/Card";
import Text from "@common/components/Text";
import Box from "@common/components/Box";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import Image from "@common/components/Image";
import {replaceTimeSeparators} from "@common/components/Video/helpers/replaceTimeSeparators";

interface OnlineStreamerInfoProps {
    user_name: string;
    avatar: string;
    game_name: string;
    started_at: string;
    width?: string;
}

const OnlineStreamerInfo: React.FC<OnlineStreamerInfoProps> = ({user_name, avatar, game_name, started_at, width}) => {
    const theme = useTheme().theme as Themes;
    const [elapsedTime, setElapsedTime] = useState<string>("00:00:00");

    useEffect(() => {
        const startTime = new Date(started_at);

        const updateElapsedTime = () => {
            const now = new Date();
            const diffInSeconds = Math.floor((now.getTime() - startTime.getTime()) / 1000);

            const hours = Math.floor(diffInSeconds / 3600);
            const minutes = Math.floor((diffInSeconds % 3600) / 60);
            const seconds = diffInSeconds % 60;

            const timeString = `${hours}h${minutes}m${seconds}s`;
            const formattedTime = replaceTimeSeparators(timeString);
            setElapsedTime(formattedTime);
        };

        const intervalId = setInterval(updateElapsedTime, 1000);

        return () => clearInterval(intervalId);
    }, [started_at]);

    return (
        <Card width={width}>
            <Box display="flex" flexDirection="column" gap="8px">
                <Box display="flex" gap="8px">
                    <Image src={avatar} alt={`Avatar ${user_name}`} borderRadius="100%" width="50px" />
                    <Box display="flex" flexDirection="column">
                        <Text fontSize="20px" fontWeight="600">{user_name}</Text>
                        <Text color={theme.colors.text.secondary} fontSize="13px" fontWeight="500">Играет в: {game_name}</Text>
                    </Box>
                </Box>
                <Text color={theme.colors.text.subtitle} fontSize="15px" fontWeight="500">Трансляция идет: {elapsedTime}</Text>
            </Box>
        </Card>
    );
};

export default OnlineStreamerInfo;
