/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";

export interface VideoIFrameProps {
    type: "video" | "channel" | "clip";
    src: string;
}

const IframeWrapper = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  z-index: 100;
`;

const VideoIFrame: React.FC<VideoIFrameProps> = ({type, src}) => {
    let url = `https://player.twitch.tv/?parent=slmsquad.ru&${type}=${type === "video" ? "v" : ""}${src}`;
    if (type === "clip") {
        url = src + "&parent=slmsquad.ru";
    }

    return (
        <IframeWrapper
            src={url}
            allowFullScreen
        >
        </IframeWrapper>
    );
};

export default VideoIFrame;
