export const getCreatedDateText = (dateString: string): string => {
    const now = new Date();
    const pastDate = new Date(dateString);
    const diffInSeconds = Math.floor((now.getTime() - pastDate.getTime()) / 1000);

    const secondsInDay = 86400;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;

    const declineWord = (number: number, forms: [string, string, string]): string => {
        const remainder10 = number % 10;
        const remainder100 = number % 100;

        if (remainder10 === 1 && remainder100 !== 11) {
            return forms[0];
        } else if ([2, 3, 4].includes(remainder10) && ![12, 13, 14].includes(remainder100)) {
            return forms[1];
        } else {
            return forms[2];
        }
    };

    if (diffInSeconds < secondsInDay) {
        return "Сегодня";
    } else if (diffInSeconds < secondsInDay * 2) {
        return "Вчера";
    } else if (diffInSeconds < secondsInMonth) {
        const days = Math.floor(diffInSeconds / secondsInDay);
        return `${days} ${declineWord(days, ['день', 'дня', 'дней'])} назад`;
    } else if (diffInSeconds < secondsInYear) {
        const months = Math.floor(diffInSeconds / secondsInMonth);
        return `${months} ${declineWord(months, ['месяц', 'месяца', 'месяцев'])} назад`;
    } else {
        const years = Math.floor(diffInSeconds / secondsInYear);
        return `${years} ${declineWord(years, ['год', 'года', 'лет'])} назад`;
    }
}