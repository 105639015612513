import {AboutModel} from "@modules/about/types/models/about.model";
import axios from "axios";

import {TwitchStream} from "@modules/online/types/models/twitchStream.model";

const apiUrl = `${process.env.REACT_APP_API_URL}/online`;

export const fetchOnline = async (): Promise<TwitchStream[]> => {
    const {data} = await axios.get<TwitchStream[]>(apiUrl);
    /*const data = new Promise<TwitchStream[]>((resolve, reject) => {
        resolve([
            {
                id: "2223376767",
                user_id: "879501793",
                user_name: "kikbutovskya",
                avatar: "https://static-cdn.jtvnw.net/jtv_user_pictures/9b18aa08-4a9a-4a0d-aa3e-b1afa87eba79-profile_image-300x300.png",
                game_name: "League Of Legends",
                title: "Online Streams",
                viewer_count: 20,
                started_at: "2024-08-13T18:02:40Z",
                thumbnail_url: "https://static-cdn.jtvnw.net/cf_vods/d1m7jfoe9zdc1j/e9091f6ffc67a0098546_kikbutovskya_51756033933_1723572156//thumb/thumb0-%{width}x%{height}.jpg"
            },
            {
                id: "2223376767",
                user_id: "879501793",
                user_name: "kikbutovskya",
                avatar: "https://static-cdn.jtvnw.net/jtv_user_pictures/9b18aa08-4a9a-4a0d-aa3e-b1afa87eba79-profile_image-300x300.png",
                game_name: "League Of Legends",
                title: "Online Streams",
                viewer_count: 20,
                started_at: "2024-08-13T18:02:40Z",
                thumbnail_url: "https://static-cdn.jtvnw.net/cf_vods/d1m7jfoe9zdc1j/e9091f6ffc67a0098546_kikbutovskya_51756033933_1723572156//thumb/thumb0-%{width}x%{height}.jpg"
            },
            {
                id: "2223376767",
                user_id: "879501793",
                user_name: "kikbutovskya",
                avatar: "https://static-cdn.jtvnw.net/jtv_user_pictures/9b18aa08-4a9a-4a0d-aa3e-b1afa87eba79-profile_image-300x300.png",
                game_name: "League Of Legends",
                title: "Online Streams",
                viewer_count: 20,
                started_at: "2024-08-13T18:02:40Z",
                thumbnail_url: "https://static-cdn.jtvnw.net/cf_vods/d1m7jfoe9zdc1j/e9091f6ffc67a0098546_kikbutovskya_51756033933_1723572156//thumb/thumb0-%{width}x%{height}.jpg"
            },
            {
                id: "2223376767",
                user_id: "879501793",
                user_name: "kikbutovskya",
                avatar: "https://static-cdn.jtvnw.net/jtv_user_pictures/9b18aa08-4a9a-4a0d-aa3e-b1afa87eba79-profile_image-300x300.png",
                game_name: "League Of Legends",
                title: "Online Streams",
                viewer_count: 20,
                started_at: "2024-08-13T18:02:40Z",
                thumbnail_url: "https://static-cdn.jtvnw.net/cf_vods/d1m7jfoe9zdc1j/e9091f6ffc67a0098546_kikbutovskya_51756033933_1723572156//thumb/thumb0-%{width}x%{height}.jpg"
            }
        ])
    })*/
    return data;
}