/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";

import PageContainer from "@common/components/PageContainer";
import PageTitle from "@common/components/PageTitle";
import {useQuery} from "@tanstack/react-query";
import {fetchStreamers} from "@modules/streamers/services/fetchStreamers";
import {Streamer} from "@modules/streamers/types/models/streamer.model";
import StreamersListGrid from "@modules/streamers/components/StreamersListGrid";

const StreamersPage: React.FC = () => {
    const {data, isLoading, error} = useQuery<Streamer[], Error>({
        queryKey: ["streamers"],
        queryFn: fetchStreamers
    });

    return (
        <PageContainer>
            <PageTitle name="Состав"/>
            <StreamersListGrid streamers={data} isLoading={isLoading} error={error} />
        </PageContainer>
    );
};

export default StreamersPage;
