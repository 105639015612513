/** @jsxImportSource @emotion/react */
import React from "react";

import {css} from "@emotion/react";

import Box from "@common/components/Box";
import StreamerStatisticBlockElement from "@modules/streamers/components/StreamerStatisticBlock/StreamerStatisticBlockElement";
import {IStatistic} from "@modules/streamers/types/interfaces/IStatistic";

interface StreamerStatisticBlockGridProps {
    statistic: IStatistic[];
}

const StreamerStatisticBlockGrid: React.FC<StreamerStatisticBlockGridProps> = ({statistic}) => {
    const stylesGrid = css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    `;

    return (
        <Box customCss={stylesGrid}>
            {statistic.map((value, index) => (
                <StreamerStatisticBlockElement key={index} value={value}/>
            ))}
        </Box>
    );
};

export default StreamerStatisticBlockGrid;
