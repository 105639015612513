/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

import {mediaQueries} from "@styles/media";

interface PageContainerProps {
    overflow?: string;
    fullSize?: boolean;
    position?: string;
}

const PageContainer = styled.div<PageContainerProps>`
    overflow: ${({overflow}) => overflow || 'auto'};
    position: ${({position}) => position || 'block'};
    display: flex;
    flex-direction: column;
    justify-content: ${({fullSize}) => fullSize ? 'center' : 'flex-start'};
    align-items: ${({fullSize}) => fullSize ? 'center' : 'flex-start'};
    width: 100%;
    height: 100%;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
        display: none;
    }

    ${mediaQueries.mobileAndTablet} {
        padding: 0 8px;
    }

    ${mediaQueries.desktop} {
        padding: 0 8px;
        ${({fullSize}) => !fullSize ? "margin-left: 100px" : ""};
    }
`;

export default PageContainer;