/** @jsxImportSource @emotion/react */
import React from "react";

import Box from "@common/components/Box";
import Icon from "@common/components/Icon";

interface MenuButtonProps {
    action: () => void;
}

export const MenuButton: React.FC<MenuButtonProps> = ({action}) => {
    return (
        <Box className="click_block" onClick={action}>
            <Icon size="38px">
                <path d="M124-245.62V-304h712v58.38H124Zm0-205.69v-58.38h712v58.38H124ZM124-657v-58.38h712V-657H124Z"/>
            </Icon>
        </Box>
    );
}