/** @jsxImportSource @emotion/react */
import React from "react";

import styled from "@emotion/styled";
import {css} from "@emotion/react";

import {IMenuItem} from "@common/types/interfaces/IMenuItem";
import {mediaQueries} from "@styles/media";
import {MenuLink} from "@common/components/Menu/MenuLink";

interface MenuProps {
    isShow: boolean;
    list: IMenuItem[];
    active?: number;
}

const MenuWrapper = styled.ul`
    position: absolute;
    list-style: none;
    text-align: center;
    top: 50%;
    left: 55%;
    transform: translateY(-50%);
    transform-style: preserve-3d;
    margin: 0;
    padding: 0;
    transition: visibility 0s .2s;
    gap: 8px;
    display: flex;
    flex-direction: column;

    ${mediaQueries.mobile} {
        left: 50%;
    }
`;

export const Menu: React.FC<MenuProps> = ({isShow, list, active}) => {
    const stylesMenu = css`
        visibility: ${isShow ? "visible" : "hidden"};
    `;

    return (
        <MenuWrapper css={stylesMenu}>
            {list.map((el) =>
                <MenuLink
                    id={el.id}
                    key={`menu-el-${el.name}`}
                    content={el.name}
                    active={active === el.id}
                    link={el.link}
                    isShow={isShow}
                />
            )}
        </MenuWrapper>
    );
}