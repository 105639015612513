/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {mediaQueries} from "@styles/media";

const styles = {
    container: css`
        position: relative;
        display: flex;
        justify-content: center;
        height: 100%;
    `,

    content: css`
        width: 100%;
        
        ${mediaQueries.desktop} {
            margin-bottom: 70px;
            margin-top: 70px;
            margin-left: 32px;
            height: calc(100% - 70px * 2);
        }
        
        ${mediaQueries.tablet} {
            margin-top: 70px;
            height: calc(100% - 60px - 70px);
        }
        
        ${mediaQueries.mobileAndTablet} {
            margin-bottom: 60px;
        }
        
        ${mediaQueries.mobile} {
            height: calc(100% - 60px * 2);
            margin-top: 60px;
        }
    `
};

export default styles;