/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import React from "react";

import 'normalize.css';

import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

const GlobalStyles: React.FC = () => {
    const theme = useTheme().theme as Themes;

    const globalStyles = css`
    @import-normalize;
      @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      body {
        font-family: "Montserrat", sans-serif;
        background-color: ${theme.colors.background};
        color: ${theme.colors.text.primary};
        overflow: hidden;
        height: 100vh;
        width: 100vw;
      }

      #root {
        height: 100%;
        width: 100%;
      }

      .click_block {
        cursor: pointer;
      }

      .click_block:hover {
        opacity: .8;
      }

      .click_block:active {
        opacity: 1;
      }

      @keyframes skeleton-loading {
        0% {
          transform: translateX(-150%);
        }
        50% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(150%);
        }
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes pageAnimationDown {
        from {
          opacity: 0;
          transform: translateY(-10%);
        }
        to {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      @keyframes pageAnimationUp {
        from {
          opacity: 0;
          transform: translateY(10%);
        }
        to {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      @keyframes pageAnimationOther {
        from {
          opacity: 0;
          transform: scale(0.8);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }

      @keyframes comeIn {
        0% {
          transform: scale(0);
        }
        1% {
          transform: scale(0)
        }
        75% {
          transform: scale(1.03)
        }
        100% {
          opacity: 1;
          transform: scale(1)
        }
      }

      @keyframes blink {
        50% {
          opacity: 0;
        }
      }
    `;

    return <Global styles={globalStyles} />;
};

export default GlobalStyles;
