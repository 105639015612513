/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import {ContactButtonType} from "@common/types/enums/ContactButtonType";
import Icon from "@common/components/Icon";
import {contactButtonList} from "@common/services/data/ContactButtonList";

interface ContactButtonProps {
    type: ContactButtonType;
    size?: string;
    url: string;
}

const ContactButtonWrapper = styled.a<{ size: string, backgroundColor: string }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
    text-decoration: none;
    transition: transform 0.2s ease;
    width: ${({size}) => size};
    height: ${({size}) => size};
    background-color: ${({backgroundColor}) => backgroundColor};;
    border: 1px solid rgba(124, 124, 124, 0.5);

    &:hover {
        transform: scale(1.1);
    }
`;

const ContactButton: React.FC<ContactButtonProps> = ({type, size = '40px', url}) => {
    const iconPath = contactButtonList[type];
    const sizeSvg = `calc(${size} - 8px)`;

    return (
        <ContactButtonWrapper href={url} size={size} backgroundColor={iconPath.color} target="_blank" rel="noopener noreferrer">
            <Icon fill="#FFF" size={sizeSvg} viewBox={iconPath.viewBox}>
                {iconPath.icon}
            </Icon>
        </ContactButtonWrapper>
    );
};

export default ContactButton;
