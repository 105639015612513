import axios from "axios";
import {TwitchClip} from "@modules/streamers/types/models/twitchClip.model";

const apiUrl = `${process.env.REACT_APP_API_URL}/streamer/clips?login=`;

export const fetchStreamerClips = async (login: string, cursor: string = ""): Promise<TwitchClip> => {
    let url = apiUrl + login;
    if (cursor !== "") {
        url += "&cursor=" + cursor;
    }
    const {data} = await axios.get<TwitchClip>(url);
    return data;
}