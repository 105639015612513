/** @jsxImportSource @emotion/react */
import React from 'react';

import {css, SerializedStyles} from '@emotion/react';

import {useTheme} from '@styles/theme/ThemeContext';
import Box from "@common/components/Box";
import TabsTextLink from "@common/components/TabsText/TabsTextLink";
import styled from "@emotion/styled";
import {Themes} from "@styles/theme/themes";

interface TabsTextProps {
    values: {id: number; name: string; fn: () => void;}[];
    activeId: number;
    fontSize?: string;
}

const Divider = styled.div<{ size: string }>`
    width: 1px;
    height: ${({size}) => size};
    background-color: ${({ theme }) => (theme as Themes).colors.line};
    margin: 0 8px;
`;

const TabsText: React.FC<TabsTextProps> = ({values, activeId, fontSize = "12px"}) => {
    const {theme} = useTheme();

    return (
        <Box display="flex" alignItems="center">
            {values.map((value, index) => (
                <React.Fragment key={value.id}>
                    <TabsTextLink isActive={value.id === activeId} fontSize={fontSize} onClick={value.fn}>
                        {value.name}
                    </TabsTextLink>
                    {index < values.length - 1 && <Divider size={fontSize}/>}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default TabsText;
