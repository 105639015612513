/** @jsxImportSource @emotion/react */
import React from "react";

import {css} from "@emotion/react";

import Box from "@common/components/Box";
import {mediaQueries} from "@styles/media";
import {NavBarList} from "@common/components/NavBar/NavBarList";
import {IMenuItem} from "@common/types/interfaces/IMenuItem";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

interface NavBarProps {
    list: IMenuItem[];
    active?: number;
    isLoading?: boolean;
}

export const NavBar: React.FC<NavBarProps> = ({list, active, isLoading = false}) => {
    const theme = useTheme().theme as Themes;

    const styleNavBar = css`
        position: absolute;

        &:before {
            content: "";
            position: absolute;
            transform: translateY(-50%);
            background-color: ${theme.colors.line};
            opacity: .35;
            z-index: 10;
        }

        ${mediaQueries.mobileAndTablet} {
            bottom: 0;
            width: 100%;
            justify-content: center;
            height: 50px;

            &:before {
                height: 2px;
                width: 90%;
            }
        }

        ${mediaQueries.desktop} {
            left: -84px;
            height: 100%;

            &:before {
                top: 50%;
                left: 50%;
                width: 2px;
                height: 70%;
                max-height: 750px;
            }
        }
        
        ${mediaQueries.maxDesktop} {
            left: -104px;
        }
    `;

    return (
        <Box customCss={styleNavBar} display="flex" alignItems="center">
            {!isLoading && <NavBarList list={list} active={active}/>}
        </Box>
    );
}