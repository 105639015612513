/** @jsxImportSource @emotion/react */
import React from "react";

import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";

import Box from "@common/components/Box";
import logo from "@image/logo.png";
import Text from "@common/components/Text";
import {mediaQueries} from "@styles/media";

interface HeaderLogoProps {
    homeLink?: string;
}

const HeaderLogoImage = styled.img`
    width: 50px;
    height: 50px;
    margin-right: 8px;
    
    ${mediaQueries.mobile} {
        width: 48px;
        height: 48px;
    }
`;

export const HeaderLogo: React.FC<HeaderLogoProps> = ({homeLink = "/"}) => {
    const navigate = useNavigate();

    const handlerNavigateHome = () => navigate(homeLink);

    return (
        <Box className="click_block" display="flex" alignItems="center" onClick={handlerNavigateHome}>
            <HeaderLogoImage src={logo} alt="SLM Squad logo" />
            <Text fontSize="16px" fontWeight="700">SLM SQUAD</Text>
        </Box>
    );
}