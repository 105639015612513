export const lightTheme = {
    colors: {
        background: '#F3F3F3',
        header: '#F3F3F3',
        text: {
            primary: '#000000',
            secondary: '',
            subtitle: ''
        },
        icon: {
            primary: '#171512',
            secondary: '',
        },
        line: '#555',
        button: {
            primary: '#007D3B'
        },
        shadow: 'rgba(0,0,0,0.85)',
        card: 'rgba(44, 44, 44, 0.8)'
    },
};

export const darkTheme = {
    colors: {
        background: '#0C0C0C',
        header: '#0C0C0C',
        text: {
            primary: '#FFFFFF',
            secondary: '#D9D9D9',
            subtitle: '#a1a1a1'
        },
        icon: {
            primary: '#E8EAED',
            secondary: '',
        },
        line: '#555',
        button: {
            primary: '#007D3B'
        },
        shadow: 'rgba(52,52,52,0.85)',
        card: 'rgba(44, 44, 44, 0.8)'
    },
};

export type Themes = typeof lightTheme;