/** @jsxImportSource @emotion/react */
import React from 'react';

import {css, SerializedStyles} from '@emotion/react';
import styled from '@emotion/styled';

import {useTheme} from '@styles/theme/ThemeContext';
import {mediaQueries} from '@styles/media';
import {Themes} from "@styles/theme/themes";

interface ButtonProps {
    width?: string;
    height?: string;
    backgroundColor?: string;
    color?: string;
    fontSize?: string;
    padding?: string;
    margin?: string;
    borderRadius?: string;
    border?: string;
    customCss?: SerializedStyles;
    children: React.ReactNode;
    onClick?: () => void;
    type?: "button" | "submit" | "reset" | undefined;
}

const StyledButton = styled.button<ButtonProps>`
    ${({theme, width, height, backgroundColor, color, fontSize, padding, margin, borderRadius, border, customCss}) => css`
        width: ${width || 'fit-content'};
        height: ${height || 'auto'};
        background-color: ${backgroundColor || (theme as Themes).colors.button.primary};
        color: ${color || (theme as Themes).colors.text.primary};
        font-size: ${fontSize || '16px'};
        padding: ${padding || '0 16px'};
        margin: ${margin || '0'};
        border-radius: ${borderRadius || '8px'};
        border: ${border || 'none'};
        ${customCss};

        &:active {
            transform: translate(0px, 1px);
        }

        ${mediaQueries.mobile} {
            font-size: calc(${fontSize || '16px'} - 2px);
        }
    `}
`;

const Button: React.FC<ButtonProps> = (props) => {
    const theme = useTheme().theme as Themes;

    return (
        <StyledButton className="click_block" {...props} theme={theme} onClick={props.onClick}>
            {props.children}
        </StyledButton>
    );
};

export default Button;
