/** @jsxImportSource @emotion/react */
import React, {useEffect, useRef} from "react";

import styled from "@emotion/styled";

import {mediaQueries} from "@styles/media";
import {IMenuItem} from "@common/types/interfaces/IMenuItem";
import {NavBarLink} from "@common/components/NavBar/NavBarLink";

interface NavBarListProps {
    list: IMenuItem[];
    active?: number;
}

const NavBarListWrapper = styled.ul`
    margin: 0;
    padding: 0;
    list-style-position: inside;
    z-index: 10;
    position: relative;
    display: flex;

    ${mediaQueries.mobileAndTablet} {
        width: 80%;
        height: 100%;
        overflow-x: auto;
        white-space: nowrap;
        margin: 0;
        padding: 0;
        list-style: none;
        scrollbar-width: none;
    }

    ${mediaQueries.desktop} {
        width: 220px;
        height: 60%;
        max-height: 750px;
        gap: 70px;
        overflow-y: scroll;
        overflow-x: hidden;
        white-space: nowrap;
        scrollbar-width: none;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
`;

export const NavBarList: React.FC<NavBarListProps> = ({list, active}) => {
    const navListRef = useRef<HTMLUListElement>(null);

    const scrollToActiveElement = (container: HTMLElement, activeElement: HTMLElement) => {
        const { offsetLeft, offsetTop, clientWidth, clientHeight } = activeElement;
        const { clientWidth: containerWidth, clientHeight: containerHeight } = container;

        const scrollLeft = clientWidth === 14 ? 0 : offsetLeft - (containerWidth / 2) + (clientWidth / 2);
        const scrollTop = offsetTop - (containerHeight / 2) + (clientHeight / 2);

        container.scrollTo({
            left: scrollLeft,
            top: scrollTop,
            behavior: 'smooth'
        });
    };

    const getActiveElement = (container: HTMLElement): HTMLElement | null => {
        return container.querySelector('#active_link') as HTMLElement | null;
    };

    useEffect(() => {
        if (navListRef.current) {
            const activeElement = getActiveElement(navListRef.current);
            if (activeElement) {
                scrollToActiveElement(navListRef.current, activeElement);
            }
        }
    }, [active]);

    return (
        <NavBarListWrapper ref={navListRef}>
            {list.map((el) =>
                <NavBarLink
                    id={active === el.id ? "active_link" : ""}
                    key={`navbar-el-${el.name}`}
                    content={el.name}
                    active={active === el.id}
                    link={el.link}
                />
            )}
        </NavBarListWrapper>
    );
}