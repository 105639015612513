import React from "react";
import Chip from "@common/components/Chip";
import {BioType} from "@modules/streamers/types/enums/BioType";
import {bioChipList} from "@modules/streamers/services/data/BioChipList";
import Text from "@common/components/Text";
import {css} from "@emotion/react";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import Box from "@common/components/Box";
import Icon from "@common/components/Icon";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";

interface StreamerBioChipProps {
    type: BioType;
    value: string;
}

const StreamerBioChip: React.FC<StreamerBioChipProps> = ({type, value}) => {
    const theme = useTheme().theme as Themes;
    const isMobile = useMediaQuery(mediaQueries.mobile);
    const bioPath = bioChipList[type];

    const stylesChip = css`
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 2px;
      white-space: nowrap;
    `;

    return (
        <Chip customCss={stylesChip}>
            <Icon viewBox={bioPath.viewBox} size={isMobile ? "18px" : "20px"}>{bioPath.icon}</Icon>
            <Box display="flex" alignItems="center" gap="6px">
                <Text fontSize={isMobile ? "13px" : "15px"} fontWeight="600" lineHeight={isMobile ? "13px" : "15px"}>{bioPath.name}:</Text>
                <Text color={theme.colors.text.secondary} fontSize="14px" fontWeight="400"
                      lineHeight="14px">{value}</Text>
            </Box>
        </Chip>
    );
};

export default StreamerBioChip;