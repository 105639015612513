/** @jsxImportSource @emotion/react */
import React from 'react';

import Card from "@common/components/Card";
import Text from "@common/components/Text";
import Box from "@common/components/Box";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";
import {TwitchStream} from "@modules/online/types/models/twitchStream.model";
import Skeleton from "@common/components/Skeleton";
import Video from "@common/components/Video/Video";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import OnlineStreamerInfo from "@modules/online/components/OnlineStreamerInfo";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import ErrorData from "@common/components/ErrorData";

interface OnlineStreamProps {
    stream?: TwitchStream;
    isLoading?: boolean;
    error?: Error | null;
}

const OnlineStream: React.FC<OnlineStreamProps> = ({stream, isLoading, error}) => {
    const isMobileAndTablet = useMediaQuery(mediaQueries.mobileAndTablet);
    const theme = useTheme().theme as Themes;

    const renderContent = () => {
        if (isLoading) {
            return (
                <>
                    <Skeleton width={isMobileAndTablet ? "100%" : "70%"} height="460px"/>
                    <Skeleton width={isMobileAndTablet ? "100%" : "30%"} height="120px"/>
                </>
            );
        }

        if (error) {
            return <ErrorData />
        }

        if (!stream) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                    <Text fontSize="20px" fontWeight="600" color={theme.colors.text.subtitle}>
                        Трансляции не найдены!
                    </Text>
                </Box>
            );
        }

        return (
            <>
                <Video
                    width={isMobileAndTablet ? "100%" : "70%"}
                    video_id={stream.user_name}
                    thumbnail={stream.thumbnail_url}
                    visible
                    information={{
                        title: stream.title,
                        online: stream.viewer_count
                    }}/>
                <OnlineStreamerInfo
                    width={isMobileAndTablet ? "100%" : "30%"}
                    user_name={stream.user_name}
                    avatar={stream.avatar}
                    game_name={stream.game_name}
                    started_at={stream.started_at}
                />
            </>
        );
    };

    return (
        <Box display="flex" width="100%" height="100%" alignItems="center">
            <Box display="flex" flexDirection={isMobileAndTablet ? "column" : "row"} width="100%" gap="16px">
                {renderContent()}
            </Box>
        </Box>
    );
};

export default OnlineStream;
