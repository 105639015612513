import {IStatisticElementRecord} from "@modules/streamers/types/interfaces/IStatisticElement";
import {StatisticType} from "@modules/streamers/types/enums/StatisticType";

export const statisticElementList: IStatisticElementRecord = {
    [StatisticType.AVG_VIEWERS]: {
        name: "Среднее число зрителей",
        color: "#18BC9C"
    },
    [StatisticType.COUNT_STREAMS]: {
        name: "Количество стримов",
        color: "#95A5A6"
    },
    [StatisticType.FOLLOWER_GAINED]: {
        name: "Фолловеры",
        color: "#3498DB"
    },
    [StatisticType.HOURS_STREAMED]: {
        name: "Часов стримов",
        color: "#FC3636"
    },
    [StatisticType.HOURS_WATCHED]: {
        name: "Часов просмотра",
        color: "#A991D4"
    },
    [StatisticType.PEAK_VIEWERS]: {
        name: "Наибольшее число зрителей",
        color: "#BCB01B"
    },
};