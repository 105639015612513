/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Card from "@common/components/Card";
import {TopStreamer} from "@modules/about/types/models/topStreamer.model";
import {mediaQueries} from "@styles/media";
import Box from "@common/components/Box";
import Text from "@common/components/Text";
import Image from "@common/components/Image";
import {getHoursText} from "@modules/about/components/aboutStatisticBlock/helpers/getHoursText";
import {getOnlineText} from "@modules/about/components/aboutStatisticBlock/helpers/getOnlineText";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import Icon from "@common/components/Icon";

interface AboutStatisticTopStreamerProps {
    streamer: TopStreamer;
    description: string;
    typeValue?: "hours" | "online" | "other";
    width?: string;
}

const AboutStatisticTopStreamer: React.FC<AboutStatisticTopStreamerProps> = ({
                                                                                 streamer,
                                                                                 description,
                                                                                 typeValue = "other",
                                                                                 width
                                                                             }) => {
    const theme = useTheme().theme as Themes;
    const {streamer: streamerInfo, value} = streamer;

    const handlerOpenStreamer = () => window.open(streamerInfo.url, "_blank");
    const displayValue = () => {
        switch (typeValue) {
            case "hours":
                return getHoursText(value);
            case "online":
                return getOnlineText(value);
            default:
                return value;
        }
    };
    const displayBackground = () => {
        switch (typeValue) {
            case "hours":
                return (
                    <Icon viewBox="0 -960 960 960" fill="#7495F6" size="100%">
                        <path
                            d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"/>
                    </Icon>
                );
            case "online":
                return (
                    <Icon viewBox="0 -960 960 1100" fill="#EA3323" size="100%">
                        <path
                            d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Z"/>
                    </Icon>
                );
            default:
                return null;
        }
    };

    const stylesCard = css`
        position: relative;
        overflow: hidden;

        ${mediaQueries.mobile} {
            flex: 0 0 80%;
        }
    `;

    const stylesBackground = css`
        position: absolute;
        bottom: -20%;
        right: -20%;
        z-index: -10;
    `;

    return (
        <Card customCss={stylesCard} width={width}>
            <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                    <Text fontSize="15px" fontWeight="600" lineHeight="16px">ТОП стример недели</Text>
                    <Text color={theme.colors.text.subtitle} fontSize="11px" fontWeight="500"
                          lineHeight="12px">{description}</Text>
                </Box>
                <Box className="click_block" display="flex" flexDirection="column" alignItems="center" gap="4px"
                     width="100%" onClick={handlerOpenStreamer}>
                    <Image src={streamerInfo.avatar} alt={`${streamerInfo.name} avatar`} borderRadius="100%"
                           width="150px" height="150px"/>
                    <Text fontSize="16px" fontWeight="600">{streamerInfo.name}</Text>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                    <Text color={theme.colors.text.secondary} fontSize="15px" fontWeight="700">{displayValue()}</Text>
                </Box>
            </Box>
            <Box customCss={stylesBackground} width="60%" height="60%">
                {displayBackground()}
            </Box>
        </Card>
    );
};

export default AboutStatisticTopStreamer;
