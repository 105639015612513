/** @jsxImportSource @emotion/react */
import React from 'react';

import {css, SerializedStyles} from '@emotion/react';

import {useTheme} from '@styles/theme/ThemeContext';
import {mediaQueries} from "@styles/media";

interface IconProps {
    size?: string;
    fill?: string;
    viewBox?: string;
    customCss?: SerializedStyles;
    children: React.ReactNode;
}

const Icon: React.FC<IconProps> = ({size, fill, viewBox, customCss, children}) => {
    const {theme} = useTheme();

    const iconStyles = css`
        fill: ${fill || theme.colors.icon.primary};
        height: ${size || "24px"};
        width: ${size || "24px"};
        ${customCss};

        // ${mediaQueries.mobile} {
        //     height: ${size ? `calc(${size} - 2px)` : '24px'};
        //     width: ${size ? `calc(${size} - 2px)` : '24px'};
        // }
    `;

    return (
        <svg css={iconStyles} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox || "0 -960 960 960"}>
            {children}
        </svg>
    );
};

export default Icon;
