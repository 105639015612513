/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";

import styles from "./styles";
import PageContainer from "@common/components/PageContainer";
import Box from "@common/components/Box";
import PageTitle from "@common/components/PageTitle";
import {useQuery} from "@tanstack/react-query";
import {AboutModel} from "@modules/about/types/models/about.model";
import {fetchAbout} from "@modules/about/services/fetchAbout";
import {TwitchStream} from "@modules/online/types/models/twitchStream.model";
import {fetchOnline} from "@modules/online/services/fetchOnline";
import OnlineStreamersBlock from "@modules/online/components/onlineStreamersBlock/OnlineStreamersBlock";
import Video from "@common/components/Video/Video";
import {TwitchVideo} from "@common/types/models/twitchVideo.model";
import OnlineStream from "@modules/online/components/OnlineStream";


const OnlinePage: React.FC = () => {
    const [activeStream, setActiveStream] = useState<TwitchStream | undefined>(undefined);

    const {data, isLoading, error} = useQuery<TwitchStream[], Error>({
        queryKey: ["streams-online"],
        queryFn: fetchOnline,
        staleTime: 1000 * 10,
    });

    const handleChangeStream = (user_name: string) => {
        if (!data) return;
        const stream = data.find(stream => stream.user_name === user_name);
        setActiveStream(stream);
    };

    useEffect(() => {
        if (!data) return;
        if (!activeStream) {
            setActiveStream(data[0]);
            return;
        }

        const idStream = data.findIndex(stream => stream.id === activeStream.id);
        if (idStream >= 0) {
            setActiveStream(data[idStream]);
        } else {
            setActiveStream(data[0]);
        }

    }, [data, activeStream]);

    return (
        <PageContainer>
            <PageTitle name="В онлайне"/>
            <Box customCss={styles.onlineContainer} display="flex" flexDirection="column" width="100%" height="100%"
                 gap="32px">
                <OnlineStreamersBlock isLoading={isLoading} list={data} onChangeStream={handleChangeStream}/>
                <OnlineStream isLoading={isLoading} stream={activeStream} error={error}/>
            </Box>
        </PageContainer>
    );
};

export default OnlinePage;
