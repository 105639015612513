/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import {mediaQueries} from "@styles/media";

interface PageTitleProps {
    name: string;
}

const PageTitle: React.FC<PageTitleProps> = ({name}) => {
    const theme = useTheme().theme as Themes;

    const stylesBox = css`
        margin-bottom: 24px;
        
        ${mediaQueries.mobile} {
            margin-bottom: 16px;
        }
    `;

    const stylesText = css`
        ${mediaQueries.mobile} {
            font-size: 24px;
            line-height: 24px;
        }
    `;

    return (
        <Box customCss={stylesBox} display="flex" justifyContent="center" alignItems="center" width="100%">
            <Text customCss={stylesText} fontSize="30px" lineHeight="30px" fontWeight="600">{name}</Text>
        </Box>
    );
};

export default PageTitle;
