import {AboutModel} from "@modules/about/types/models/about.model";
import axios from "axios";

import {TwitchVideo} from "@common/types/models/twitchVideo.model";

const apiUrl = `${process.env.REACT_APP_API_URL}/about/lastStreams`;

export const fetchLastStreams = async (): Promise<TwitchVideo[]> => {
    const {data} = await axios.get<TwitchVideo[]>(apiUrl);
    return data;
}