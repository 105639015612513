/** @jsxImportSource @emotion/react */
import React from "react";

import {css, SerializedStyles} from "@emotion/react";

import Card from "@common/components/Card";
import {mediaQueries} from "@styles/media";
import VideoIFrame from "@common/components/Video/VideoIFrame";

interface VideoModalProps {
    video_id: string;
    type?: "video" | "channel" | "clip";
}

const VideoModal: React.FC<VideoModalProps> = ({video_id, type = "video"}) => {
    const stylesVideoModal = css`
        aspect-ratio: 16/9;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        ${mediaQueries.desktop} {
            width: 60vw;
            max-width: 1200px;
        }
        
        ${mediaQueries.mobileAndTablet} {
            width: 92vw;
        }
    `;

    return (
        <Card customCss={stylesVideoModal}>
            <VideoIFrame type={type} src={video_id} />
        </Card>
    );
};

export default VideoModal;
