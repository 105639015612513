import React from "react";

import {useQuery} from "@tanstack/react-query";
import {css} from "@emotion/react";

import CategoryContainer from "@common/components/CategoryContainer/CategoryContainer";
import Skeleton from "@common/components/Skeleton";
import {TwitchVideo} from "@common/types/models/twitchVideo.model";
import {fetchLastStreams} from "@modules/about/services/fetchLastStreams";
import Video from "@common/components/Video/Video";
import Carousel from "@common/components/Carousel/Carousel";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";
import ErrorData from "@common/components/ErrorData";
import Text from "@common/components/Text";
import {useTheme} from "@styles/theme/ThemeContext";
import Card from "@common/components/Card";
import {Themes} from "@styles/theme/themes";
import Box from "@common/components/Box";

const AboutLastStream: React.FC = () => {
    const isMobile = useMediaQuery(mediaQueries.mobile);
    const isTablet = useMediaQuery(mediaQueries.tablet);
    const theme = useTheme().theme as Themes;

    const {data, isLoading, error} = useQuery<TwitchVideo[], Error>({
        queryKey: ["about-last-streams"],
        queryFn: fetchLastStreams,
    });

    const stylesEmptyCard = css`
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    const getItemWidth = () => {
        if (isMobile) return "80%";
        if (isTablet) return "50%";
        return "33%";
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <Carousel width="100%" itemWidth={getItemWidth()}>
                    {Array.from({ length: 3 }).map((_, idx) => (
                        <Box key={`carousel-skeleton-${idx}`} display="flex" flexDirection="column" width="100%" gap="8px">
                            <Skeleton width="100%" height="200px" />
                            <Skeleton width="100%" height="22px" borderRadius="2px" />
                        </Box>
                    ))}
                </Carousel>
            );
        }

        if (error) {
            return (
                <Card customCss={stylesEmptyCard}>
                    <ErrorData/>
                </Card>
            );
        }

        if (!data || data.length === 0) {
            return (
                <Card customCss={stylesEmptyCard}>
                    <Text fontSize="16px" fontWeight="600" color={theme.colors.text.subtitle}>
                        Последние трансляции не найдены!
                    </Text>
                </Card>
            );
        }

        return (
            <Carousel width="100%" itemWidth={getItemWidth()}>
                {data.map((video) => (
                    <Video key={video.id} width="100%" thumbnail={video.thumbnail_url} video_id={video.id} view={video.view_count}
                           created={video.created_at} duration={video.duration}
                           information={{title: video.title, channel: video.user_name}}/>
                ))}
            </Carousel>
        );
    };

    return <CategoryContainer title="Последние трансляции">{renderContent()}</CategoryContainer>;
};

export default AboutLastStream;
