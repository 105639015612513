/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {mediaQueries} from "@styles/media";

const styles = {
    slmBlock: css`
        position: relative;
        max-width: 790px;
        max-height: 475px;
        
        &:before {
            content: "";
            position: absolute;
            bottom: -4px;
            left: -15px;
            right: 0;
            height: 2px;
            background-color: #282828;
        }
        
        &:after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 10px;
            width: 30px;
            height: 4px;
            background-color: #007d3b;
        }
        
        ${mediaQueries.mobile} {
            height: 330px;
        }
        
        ${mediaQueries.tablet} {
            height: 430px;
            
            &:before {
                bottom: -16px;
            }
            
            &:after {
                bottom: -18px;
            }
        }
    `,
    leftSide: css`
        height: 80%;
        z-index: 5;
        
        ${mediaQueries.mobile} {
            justify-content: center;
        }
    `
};

export default styles;