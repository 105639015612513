/** @jsxImportSource @emotion/react */
import React from "react";
import {css} from "@emotion/react";

import {mediaQueries} from "@styles/media";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import {useNavigate} from "react-router-dom";

interface NavBarLinkProps {
    id?: string;
    content?: string;
    active?: boolean;
    link?: string;
}

export const NavBarLink: React.FC<NavBarLinkProps> = ({id, content, active, link = "/"}) => {
    const theme = useTheme().theme as Themes;
    const navigate = useNavigate();

    const handlerNavigate = () => navigate(link);

    const styleNavBarLink = css`
        cursor: pointer;
        position: relative;
        color: ${active ? theme.colors.button.primary : theme.colors.text.primary};

        ${active && "transition: color .4s ease-in-out;"}
        &::marker {
            color: ${active ? theme.colors.button.primary : theme.colors.text.primary};
            font-size: 10px;
            ${active && "transition: color .4s ease-in-out;"}
        }

        &:before {
            content: "${content}";
            position: absolute;
            color: ${active ? theme.colors.text.primary : theme.colors.line};
            font-size: 14px;
            line-height: 14px;
            font-weight: ${active ? 600 : 300};
            height: 20px;
            width: ${active ? '80px' : '100px'};
            display: block;
        }

        ${mediaQueries.mobileAndTablet} {
            bottom: 8px;
            height: 26px;
            flex: 0 0 36%;
            box-sizing: border-box;
            padding: 10px 20px;
            text-align: center;

            &:after {
                content: '';
                position: absolute;
                bottom: -9px;
                left: 50%;
                transform: translateX(-50%);
                width: 3px;
                height: 3px;
                background-color: ${active ? theme.colors.button.primary : theme.colors.text.primary};
                ${active && "transition: background-color .4s ease-in-out;"}
                border-radius: 50%;
            }

            &:before {
                left: 3px;
                width: 100%;
                ${active && "text-align: center;"}
            }
        }

        ${mediaQueries.desktop} {
            left: 8px;

            &:before {
                width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                top: 5px;
                left: 10px;
            }
        }

        ${mediaQueries.maxDesktop} {
            &:before {
                left: ${active ? '-110px' : '10px'};
                ${active && "text-align: right;"}
            }
        }
    `;

    const styleSpan = css`
        position: relative;
        top: 3px;
        left: 10px;
        color: ${theme.colors.text.primary};
        font-size: 14px;
        font-weight: 300;
        opacity: ${active ? 1 : 0};
        visibility: ${active ? 'visible' : 'hidden'};
        ${active && "transition: opacity .4s ease-in-out;"}
    `;

    return (
        <li id={id} className="click_block" css={styleNavBarLink} onClick={handlerNavigate}>
            <span css={styleSpan}/>
        </li>
    );
}
