export const getOnlineText = (value: number) => {
    const absValue = Math.abs(value);
    const lastDigit = absValue % 10;
    const lastTwoDigits = absValue % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return `${value} зрителей`;
    }

    switch (lastDigit) {
        case 1:
            return `${value} зритель`;
        case 2:
        case 3:
        case 4:
            return `${value} зрителя`;
        default:
            return `${value} зрителей`;
    }
}