import React, { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchStreamers } from "@modules/streamers/services/fetchStreamers";
import { Streamer } from "@modules/streamers/types/models/streamer.model";

interface StreamersContextType {
    streamers: Streamer[];
    isLoading: boolean;
    error: Error | null;
}

const StreamersContext = createContext<StreamersContextType>({
    streamers: [],
    isLoading: false,
    error: null,
});

export const useStreamersContext = () => useContext(StreamersContext);

export const StreamersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { data = [], isLoading, error } = useQuery<Streamer[], Error>({
        queryKey: ["streamers"],
        queryFn: fetchStreamers,
    });

    return (
        <StreamersContext.Provider value={{ streamers: data, isLoading, error }}>
            {children}
        </StreamersContext.Provider>
    );
};