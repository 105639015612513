/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { css, SerializedStyles } from "@emotion/react";

import Box from "@common/components/Box";
import PerspectiveLayout from "@common/components/Layout/PerspectiveLayout";
import Container from "@common/components/Container";
import { Header } from "@common/components/Header/Header";
import { NavBar } from "@common/components/NavBar/NavBar";
import { MenuType } from "@common/types/enums/MenuType";
import { getMenuList } from "@common/services/getMenuList";
import { useTheme } from "@styles/theme/ThemeContext";
import { Themes } from "@styles/theme/themes";
import { useStreamersContext } from "@common/context/StreamersContext";
import styles from "@common/components/Layout/MainLayout/styles";

interface MainLayoutProps {}

const MainLayoutWrapper = styled(Box)`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
`;

const WrapperContent = styled(Box)<{ animationPage: SerializedStyles | null }>`
    ${({ animationPage }) => animationPage && animationPage};
`;

export const MainLayout: React.FC<MainLayoutProps> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const theme = useTheme().theme as Themes;
    const [backLinkHeader, setBackLinkHeader] = React.useState<string>("");
    const { streamers } = useStreamersContext();

    const isThrottled = useRef(false);
    const touchStartY = useRef(0);
    const contentLayoutRef = useRef<HTMLDivElement | null>(null);

    const [animationPage, setAnimationPage] = useState<SerializedStyles | null>(null);
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [navbarType, setNavbarType] = useState<MenuType>(MenuType.NAVIGATION);
    const [activeMenuLinkId, setActiveMenuLinkId] = useState(-1);

    const toggleMenu = useCallback(() => {
        setIsMenuActive((prevState) => !prevState);
    }, []);

    const determineDirection = useCallback(
        (newId: number): "up" | "down" | "other" => {
            if (activeMenuLinkId === -1) return "other";
            if (newId > activeMenuLinkId) return "down";
            if (newId < activeMenuLinkId) return "up";
            return "other";
        },
        [activeMenuLinkId]
    );

    const handleMenuLinkChange = useCallback(
        (id: number, direction: "up" | "down" | "other") => {
            const animation = css`
                animation: ${direction === "up"
                ? "pageAnimationUp"
                : direction === "down"
                    ? "pageAnimationDown"
                    : "pageAnimationOther"} .8s forwards;
            `;
            setAnimationPage(animation);
            setIsMenuActive(false);
            setActiveMenuLinkId(id);
        },
        []
    );

    const handleRouteChange = useCallback(
        (path: string) => {
            const splitPath = path.split(/(?=\/)/);
            const currentMenuType = splitPath[0] === "/streamers" && splitPath.length > 1 ? MenuType.STREAMERS : MenuType.NAVIGATION;

            setNavbarType(currentMenuType);
            const menuList = getMenuList(currentMenuType, streamers);
            const navigateLink = menuList.find((link) => link.link === path);

            if (splitPath[0] === "/streamers" && splitPath.length > 1) {
                setBackLinkHeader("/streamers")
            } else {
                setBackLinkHeader("");
            }

            if (navigateLink && navigateLink.id !== activeMenuLinkId) {
                const direction = determineDirection(navigateLink.id);
                handleMenuLinkChange(navigateLink.id, direction);
                navigate(navigateLink.link);
            }
        },
        [activeMenuLinkId, streamers, determineDirection, handleMenuLinkChange, navigate]
    );

    useEffect(() => {
        handleRouteChange(pathname);
    }, [pathname, handleRouteChange]);

    useEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            touchStartY.current = e.touches[0].clientY;
        };

        const handleScroll = (e: WheelEvent | TouchEvent) => {
            if (isThrottled.current || isMenuActive) return;

            const currentDiv = contentLayoutRef.current?.firstElementChild as HTMLElement;
            if (!currentDiv) return;

            let deltaY = 0;
            if (e instanceof WheelEvent) {
                deltaY = e.deltaY;
            } else {
                const touchEndY = (e as TouchEvent).changedTouches[0].clientY;
                deltaY = touchStartY.current - touchEndY;
            }

            const scrollableElements = currentDiv.querySelectorAll("#scrollable");
            for (let i = 0; i < scrollableElements.length; i++) {
                const scrollableElement = scrollableElements[i];
                if (scrollableElement === e.target || scrollableElement.contains(e.target as Node)) {
                    e.preventDefault();
                    return;
                }
            }

            const menuList = getMenuList(navbarType, streamers);
            if (deltaY > 30 && currentDiv.scrollTop + 1 + currentDiv.clientHeight >= currentDiv.scrollHeight) {
                const numberLink = activeMenuLinkId === menuList.length - 1 ? 0 : activeMenuLinkId + 1;
                handleRouteChange(menuList[numberLink].link);
            } else if (deltaY < -30 && currentDiv.scrollTop === 0) {
                const numberLink = activeMenuLinkId === 0 ? menuList.length - 1 : activeMenuLinkId - 1;
                handleRouteChange(menuList[numberLink].link);
            }

            isThrottled.current = true;
            setTimeout(() => {
                isThrottled.current = false;
            }, 1000);
        };

        const currentDiv = contentLayoutRef.current?.firstElementChild as HTMLElement;
        if (currentDiv) {
            currentDiv.addEventListener("wheel", handleScroll);
            currentDiv.addEventListener("touchstart", handleTouchStart);
            currentDiv.addEventListener("touchend", handleScroll);
        }

        return () => {
            if (currentDiv) {
                currentDiv.removeEventListener("wheel", handleScroll);
                currentDiv.removeEventListener("touchstart", handleTouchStart);
                currentDiv.removeEventListener("touchend", handleScroll);
            }
        };
    }, [activeMenuLinkId, isMenuActive, handleRouteChange]);

    const stylesMainLayoutWrapper = css`
        outline: 30px solid ${theme.colors.button.primary};
    `;

    const stylesOverlayLayout = css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1005;
        pointer-events: all;
        box-shadow: 0 0 45px 5px ${theme.colors.shadow};
    `;

    return (
        <PerspectiveLayout isShow={isMenuActive} list={getMenuList(MenuType.NAVIGATION)} active={activeMenuLinkId}>
            <MainLayoutWrapper css={stylesMainLayoutWrapper}>
                {isMenuActive && <Box customCss={stylesOverlayLayout} onClick={toggleMenu} />}
                <Container css={styles.container}>
                    <Header openMenu={toggleMenu} backLink={backLinkHeader} />
                    <NavBar list={getMenuList(navbarType, streamers)} active={activeMenuLinkId} />
                    <WrapperContent ref={contentLayoutRef} customCss={styles.content} display="flex" width="100%" animationPage={animationPage}>
                        <Outlet />
                    </WrapperContent>
                </Container>
            </MainLayoutWrapper>
        </PerspectiveLayout>
    );
};
