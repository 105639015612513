import React from "react";

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import {css, SerializedStyles} from "@emotion/react";
import {mediaQueries} from "@styles/media";
import styled from "@emotion/styled";
import Icon from "@common/components/Icon";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

interface CarouselButtonProps {
    arrow: "right" | "left";
    size?: string;
    onClick?: () => void;
}

const CarouselArrowButton = styled.button<{ customCss?: SerializedStyles }>`
    ${({customCss}) => css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        border-radius: 100%;
        cursor: pointer;
        padding: 8px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        ${customCss};

        &:focus {
            outline: none;
        }
    `}
`;

const CarouselButton: React.FC<CarouselButtonProps> = ({arrow, size = "24px", onClick}) => {
    const theme = useTheme().theme as Themes;

    const iconArrow = arrow === "left" ?
        <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/> :
        <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z"/>;

    const stylesButton = css`
        background-color: ${theme.colors.shadow};
        ${arrow === "left" ? 'left: 10px' : 'right: 10px'}
    `;

    return (
        <CarouselArrowButton className="click_block" customCss={stylesButton} onClick={onClick}>
            <Icon size={size} viewBox="0 -960 960 960" fill={theme.colors.text.secondary}>
                {iconArrow}
            </Icon>
        </CarouselArrowButton>
    );
};

export default CarouselButton;