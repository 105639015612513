/** @jsxImportSource @emotion/react */
import React, {useState} from "react";

import {css} from "@emotion/react";

import CategoryContainer from "@common/components/CategoryContainer/CategoryContainer";
import Box from "@common/components/Box";
import TabsText from "@common/components/TabsText/TabsText";
import Card from "@common/components/Card";
import StreamerStatisticBlockGrid from "@modules/streamers/components/StreamerStatisticBlock/StreamerStatisticBlockGrid";
import {StatisticWeek} from "@modules/streamers/types/models/statisticWeek.model";
import {Statistic} from "@modules/streamers/types/models/statistic.model";
import Skeleton from "@common/components/Skeleton";
import {converterStatisticType} from "@modules/streamers/components/StreamerStatisticBlock/helpers/converterStatisticType";

interface StreamerStatisticBlockProps {
    isLoading?: boolean;
    weekStatistic?: StatisticWeek;
    allStatistic?: Statistic;
}

const StreamerStatisticBlock: React.FC<StreamerStatisticBlockProps> = ({isLoading, weekStatistic, allStatistic}) => {
    const [period, setPeriod] = useState<number>(0);

    const periods = [
        {id: 0, name: "Неделя", fn: () => setPeriod(0)},
        {id: 1, name: "Все время", fn: () => setPeriod(1)}
    ];

    const stylesGrid = css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    `;

    const renderSkeleton = () => (
        <Box customCss={stylesGrid}>
            {[...Array(6)].map((_, idx) => (
                <Skeleton key={idx} height="80px"/>
            ))}
        </Box>
    );

    const renderStatisticGrid = () => {
        const currentStatistic = period === 0 ? weekStatistic : allStatistic;
        return currentStatistic ? (
            <StreamerStatisticBlockGrid statistic={converterStatisticType(currentStatistic)}/>
        ) : null;
    };

    return (
        <CategoryContainer title="Статистика" sizeTitle="18px"
                           rightSide={!isLoading && <TabsText values={periods} activeId={period}/>}>
            <Card>
                {isLoading ? renderSkeleton() : renderStatisticGrid()}
            </Card>
        </CategoryContainer>
    );
};

export default StreamerStatisticBlock;
