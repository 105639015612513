/** @jsxImportSource @emotion/react */
import React from "react";

import styled from '@emotion/styled';
import {css, SerializedStyles} from "@emotion/react";

import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

interface ChipProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    padding?: string;
    customCss?: SerializedStyles;
    children?: React.ReactNode;
}

const ChipWrapper = styled.div<ChipProps>`
    width: ${({width}) => width || 'fit-content'};
    height: ${({height}) => height || 'fit-content'};
    border-radius: ${({borderRadius}) => borderRadius || '8px'};
    padding: ${({padding}) => padding || '4px'};
    box-sizing: border-box;
`;

const Chip: React.FC<ChipProps> = ({children, width, padding, height, borderRadius, customCss}) => {
    const theme = useTheme().theme as Themes;

    const stylesCard = css`
        background-color: ${theme.colors.card};
        ${customCss}
    `;

    return (
        <ChipWrapper css={stylesCard} width={width} padding={padding} height={height} borderRadius={borderRadius}>
            {children}
        </ChipWrapper>
    );
};

export default Chip;
