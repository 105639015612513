/** @jsxImportSource @emotion/react */
import React from "react";

import styled from '@emotion/styled';
import {css, SerializedStyles} from "@emotion/react";

import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

interface CardProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    padding?: string;
    customCss?: SerializedStyles;
    className?: string;
    children?: React.ReactNode;
}

const CardWrapper = styled.div<CardProps>`
    box-sizing: border-box;
`;

const Card: React.FC<CardProps> = ({children, className, width, height, padding, borderRadius, customCss}) => {
    const theme = useTheme().theme as Themes;

    const stylesCard = css`
        background-color: ${theme.colors.card};
        box-shadow: 0 0 6px 0 ${theme.colors.shadow};
        width: ${width || '100%'};
        height: ${height || 'auto'};
        border-radius: ${borderRadius || '16px'};
        padding: ${padding || '16px'};
        ${customCss}
    `;

    return (
        <CardWrapper className={className} css={stylesCard}>
            {children}
        </CardWrapper>
    );
};

export default Card;
