/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";
import Card from "@common/components/Card";
import CategoryContainer from "@common/components/CategoryContainer/CategoryContainer";
import {css} from "@emotion/react";
import Skeleton from "@common/components/Skeleton";
import {keepPreviousData, useQuery, useQueryClient} from "@tanstack/react-query";
import {ITwitchClip} from "@modules/streamers/types/interfaces/ITwitchClip";
import {StatisticGame} from "@modules/streamers/types/models/statisticGame.model";
import {fetchStreamerGames} from "@modules/streamers/services/fetchStreamerGames";
import StreamerGamesTable from "@modules/streamers/components/StreamerGamesBlock/StreamerGamesTable";

interface StreamerGamesBlockProps {
    isLoading?: boolean;
    login: string | undefined;
}

const StreamerGamesBlock: React.FC<StreamerGamesBlockProps> = ({isLoading, login = ""}) => {
    const {
        data: streamerGames,
        isLoading: isLoadingStreamerClips,
        error: errorStreamerClips
    } = useQuery<StatisticGame[], Error>({
        queryKey: [`streamer-games-${login}`],
        queryFn: () => fetchStreamerGames(login),
        placeholderData: keepPreviousData,
    });

    return (
        <CategoryContainer title="Статистика категорий" sizeTitle="18px">
            <Card>
                <StreamerGamesTable data={streamerGames}/>
            </Card>
        </CategoryContainer>
    );
};

export default StreamerGamesBlock;
