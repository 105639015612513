import React from "react";

import {css} from "@emotion/react";
import {useQuery} from "@tanstack/react-query";

import Box from "@common/components/Box";
import CategoryContainer from "@common/components/CategoryContainer/CategoryContainer";
import Skeleton from "@common/components/Skeleton";
import {mediaQueries} from "@styles/media";
import {fetchStatisticSquad} from "@modules/about/services/fetchStatisticSquad";
import {AboutStatistic} from "@modules/about/types/models/aboutStatistic.model";
import Card from "@common/components/Card";
import ErrorData from "@common/components/ErrorData";
import Text from "@common/components/Text";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import AboutStatisticSquad from "@modules/about/components/aboutStatisticBlock/AboutStatisticSquad";
import useMediaQuery from "@common/hooks/useMediaQuery";
import AboutStatisticTopStreamer from "@modules/about/components/aboutStatisticBlock/AboutStatisticTopStreamer";
import {getCreatedDateText} from "@common/services/getCreatedDateText";

const AboutStatisticBlock: React.FC = () => {
    const theme = useTheme().theme as Themes;
    const isMobileAndTablet = useMediaQuery(mediaQueries.mobileAndTablet);
    const {data, isLoading, error} = useQuery<AboutStatistic, Error>({
        queryKey: ["statistic-squad"],
        queryFn: fetchStatisticSquad,
    });

    const stylesEmptyCard = css`
        display: flex;
        justify-content: center;
        align-items: center;
    `;

    const stylesContainer = css`
        ${mediaQueries.mobileAndTablet} {
            flex-direction: column;
        }
    `;

    const stylesSLMStatistic = css`
        ${mediaQueries.mobileAndTablet} {
            width: 100%;
        }
    `;

    const stylesSLMStatisticEmpty = css`
        ${stylesSLMStatistic};
        ${stylesEmptyCard};
    `;

    const stylesTopStreamersBox = css`
        ${mediaQueries.mobileAndTablet} {
            width: 100%;
        }

        ${mediaQueries.mobile} {
            overflow-x: auto;
        }
    `;

    const stylesTopStreamer = css`
        ${mediaQueries.mobile} {
            flex: 0 0 80%;
        }
    `;

    const stylesTopStreamerEmpty = css`
        ${stylesTopStreamer};
        ${stylesEmptyCard};
    `;

    const renderContent = () => {
        if (isLoading) {
            return (
                <Box customCss={stylesContainer} display="flex" gap="16px" width="100%">
                    <Skeleton customCss={stylesSLMStatistic} width="56%" height="200px"/>
                    <Box customCss={stylesTopStreamersBox} display="flex" width="44%" gap="16px">
                        <Skeleton customCss={stylesTopStreamer} width="50%" height="280px"/>
                        <Skeleton customCss={stylesTopStreamer} width="50%" height="280px"/>
                    </Box>
                </Box>
            );
        }

        if (error || !data) {
            return (
                <Box customCss={stylesContainer} display="flex" gap="16px" width="100%">
                    <Card customCss={stylesSLMStatisticEmpty} width="56%" height="200px">
                        <ErrorData/>
                    </Card>
                    <Box customCss={stylesTopStreamersBox} display="flex" width="44%" gap="16px">
                        <Card customCss={stylesTopStreamerEmpty} width="50%" height="280px">
                            <ErrorData/>
                        </Card>
                        <Card customCss={stylesTopStreamerEmpty} width="50%" height="280px">
                            <ErrorData/>
                        </Card>
                    </Box>
                </Box>
            );
        }

        if (data && (!data.top_streamer_hours || !data.top_streamer_hours)) {
            return (
                <Box customCss={stylesContainer} display="flex" gap="16px" width="100%">
                    <AboutStatisticSquad statistic={data} width={isMobileAndTablet ? "100%" : "56%"}/>
                    <Box customCss={stylesTopStreamersBox} display="flex" width="44%" gap="16px">
                        <Card customCss={stylesTopStreamerEmpty} width="50%" height="280px">
                            <Text fontSize="16px" fontWeight="600" color={theme.colors.text.subtitle}>
                                Топ стример по часам стримов не найден!
                            </Text>
                        </Card>
                        <Card customCss={stylesTopStreamerEmpty} width="50%" height="280px">
                            <Text fontSize="16px" fontWeight="600" color={theme.colors.text.subtitle}>
                                Топ стример по онлайну не найден!
                            </Text>
                        </Card>
                    </Box>
                </Box>
            );
        }

        return (
            <Box customCss={stylesContainer} display="flex" gap="16px" width="100%">
                <AboutStatisticSquad statistic={data} width={isMobileAndTablet ? "100%" : "60%"}/>
                <Box customCss={stylesTopStreamersBox} display="flex" width="40%" gap="16px">
                    <AboutStatisticTopStreamer streamer={data.top_streamer_hours} description="по проведенным стримам"
                                               width="50%" typeValue="hours"/>
                    <AboutStatisticTopStreamer streamer={data.top_streamer_online} description="по наибольшему онлайну"
                                               width="50%" typeValue="online"/>
                </Box>
            </Box>
        );
    };

    const displayLastRefresh = () => data ? <Text color={theme.colors.text.subtitle} fontSize="12px" fontWeight="300"
                                                  lineHeight="12px">Обновлено: {getCreatedDateText(data.refresh_date)}</Text> : null;

    return (
        <CategoryContainer title="Статистика" rightSide={displayLastRefresh()}>
            {renderContent()}
        </CategoryContainer>
    );
};

export default AboutStatisticBlock;