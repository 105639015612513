/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import slmImage from "@assets/img/main/main.png";
import Image from "@common/components/Image";
import {mediaQueries} from "@styles/media";

const MainSLMImage: React.FC = () => {
    const stylesImage = css`
        position: absolute;
        right: -16px;
        top: auto;
        bottom: auto;
        aspect-ratio: 1/1;
        width: 430px;
        max-height: 475px;
        
        ${mediaQueries.mobile} {
            opacity: 50%;
            width: 300px;
            right: -24px;
        }
    `;

    return (
        <Image css={stylesImage} src={slmImage} alt="SLM Squad image" />
    );
};

export default MainSLMImage;
