/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Card from "@common/components/Card";
import List from "@common/components/List/List";
import {IList} from "@common/types/interfaces/IList";
import {AboutStatistic} from "@modules/about/types/models/aboutStatistic.model";
import {formatFollowers} from "@modules/about/components/aboutStatisticBlock/helpers/formatFollowers";
import {formatValue} from "@modules/about/components/aboutStatisticBlock/helpers/formatValue";

interface AboutStatisticSquadProps {
    statistic: AboutStatistic;
    width?: string;
}

const AboutStatisticSquad: React.FC<AboutStatisticSquadProps> = ({statistic, width}) => {
    const {sum_followers, popular_category, max_viewers, avg_viewers, avg_streamed_hours} = statistic;

    const listInfo: IList[] = [
        {name: "Суммарная аудитория", value: formatFollowers(sum_followers)},
        {name: "Среднее количество зрителей на стриме", value: formatValue(avg_viewers)},
        {name: "Максимальное количество зрителей на одном стриме", value: formatValue(max_viewers)},
        {name: "Наиболее используемая категория на Twitch", value: popular_category || "N/A"},
        {name: "Среднее время одного стрима", value: formatValue(avg_streamed_hours, "час(а)")},
    ];

    const stylesCard = css`
        display: flex;
        flex-direction: column;
        gap: 16px;
    `;

    return (
        <Card customCss={stylesCard} width={width}>
            <List list={listInfo} width="100%"/>
        </Card>
    );
};

export default AboutStatisticSquad;
