/** @jsxImportSource @emotion/react */
import React from 'react';

import {css, SerializedStyles} from "@emotion/react";

import Box from "@common/components/Box";
import {Contact} from "@common/types/models/contact.model";
import ContactButton from "@common/components/ContactButton";
import {getRandomWord} from "@common/services/getRandomWord";

interface ContactsBlockProps {
    isLoading?: boolean;
    contacts: Contact[] | undefined;
    size?: string;
    customCss?: SerializedStyles;
}

const ContactsBlock: React.FC<ContactsBlockProps> = ({isLoading = false, contacts, size = "16px", customCss}) => {
    return (
        <Box customCss={customCss} display="flex" alignItems="center" justifyContent="center" width="100%" gap="8px">
            {contacts && !isLoading && (contacts.map((contact: Contact) =>
                <ContactButton key={`contact-item-${getRandomWord(8)}`} type={contact.type} url={contact.link} size={size} />
            ))}
        </Box>
    );
};

export default ContactsBlock;
