import React from "react";

import styled from "@emotion/styled";
import {css} from "@emotion/react";

import Box from "@common/components/Box";
import Skeleton from "@common/components/Skeleton";
import Image from "@common/components/Image";
import {Contact} from "@common/types/models/contact.model";
import Text from "@common/components/Text";
import {getFollowersText} from "@modules/streamers/services/getFollowersText";
import Chip from "@common/components/Chip";
import ContactsBlock from "@common/components/ContactsBlock";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import StreamerIndicatorOnline from "@modules/streamers/components/StreamerIndicatorOnline";
import {mediaQueries} from "@styles/media";
import useMediaQuery from "@common/hooks/useMediaQuery";

interface StreamerDetailBannerProps {
    isLoading?: boolean;
    bannerImg?: string;
    name?: string;
    login?: string;
    followers?: number;
    avatar?: string;
    contacts?: Contact[];
    isOnline?: boolean;
}

const StreamerDetailBannerWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  width: 100%;
  height: 200px;
  overflow: hidden;

  ${mediaQueries.mobile} {
    height: 150px;
  }
`;

const BannerBackground = styled.div<{ bannerImg?: string }>`
  background-image: ${({bannerImg}) => `url(${bannerImg})` || "none"};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to top, transparent 0%, #0c0c0c 30%, #0c0c0c 100%);
  box-shadow: inset 0 0 277px 20px #0e0e0e;
`;

const ContentWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;

  ${mediaQueries.mobile} {
    padding: 8px;
  }
`;

const StreamerBanner: React.FC<StreamerDetailBannerProps> = ({
                                                                 isLoading,
                                                                 bannerImg,
                                                                 name,
                                                                 login,
                                                                 avatar = "",
                                                                 followers = 0,
                                                                 contacts,
                                                                 isOnline = false
                                                             }) => {
    const theme = useTheme().theme as Themes;
    const isMobile = useMediaQuery(mediaQueries.mobile);

    const customChip = css`
      padding: 8px;
      background: ${theme.colors.shadow};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      ${mediaQueries.mobile} {
        padding: 4px;
        border-radius: 4px;
      }
    `;

    const stylesRightBox = css`
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px;
      height: calc(100% - 8px);
    `;

    const stylesContactBox = css`
      ${mediaQueries.mobile} {
        margin-top: 8px;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    `;

    const renderSkeleton = () => (
        <>
            <Skeleton height={isMobile ? "150px" : "200px"} width="100%">
                <Box display="flex" width="100%" height="100%" justifyContent="space-between"
                     padding={isMobile ? "8px" : "16px"}>
                    <Box display="flex" alignItems="center" height="100%" gap="16px">
                        <Skeleton height={isMobile ? "90px" : "140px"} width={isMobile ? "90px" : "140px"}
                                  borderRadius="100%"/>
                        <Box display="flex" flexDirection="column" gap="8px">
                            <Skeleton height={isMobile ? "18px" : "30px"} width={isMobile ? "160px" : "260px"}
                                      borderRadius="8px"/>
                            <Skeleton height={isMobile ? "12px" : "16px"} width="100px" borderRadius="4px"/>
                        </Box>
                    </Box>
                    <Box customCss={stylesRightBox} display="flex" flexDirection="column" height="100%"
                         justifyContent="space-between"
                         alignItems="end">
                        <Skeleton height={isMobile ? "20px" : "30px"} width={isMobile ? "70px" : "90px"}
                                  borderRadius="8px"/>
                        {!isMobile && <Box customCss={stylesContactBox} display="flex" gap="8px">
                            <Skeleton height="24px" width="24px" borderRadius="6px"/>
                            <Skeleton height="24px" width="24px" borderRadius="6px"/>
                            <Skeleton height="24px" width="24px" borderRadius="6px"/>
                        </Box>}
                    </Box>
                </Box>
            </Skeleton>
            {isMobile && <Box customCss={stylesContactBox} display="flex" gap="8px">
                <Skeleton height="24px" width="24px" borderRadius="6px"/>
                <Skeleton height="24px" width="24px" borderRadius="6px"/>
                <Skeleton height="24px" width="24px" borderRadius="6px"/>
            </Box>}
        </>
    );

    return isLoading ? (
        renderSkeleton()
    ) : (
        <>
            <StreamerDetailBannerWrapper>
                <BannerBackground bannerImg={bannerImg}/>
                <ContentWrapper>
                    <Box display="flex" alignItems="center" height="100%" gap="16px">
                        <Image src={avatar} alt={`Avatar streamer ${login}`} borderRadius="100%"
                               width={isMobile ? "90px" : "140px"}/>
                        <Box display="flex" flexDirection="column" gap="8px">
                            <Text fontSize={isMobile ? "18px" : "38px"} fontWeight={isMobile ? "700" : "600"}
                                  lineHeight={isMobile ? "18px" : "38px"}>
                                {name}
                            </Text>
                            <Text color={theme.colors.text.secondary} fontSize={isMobile ? "12px" : "14px"}
                                  fontWeight="400" lineHeight={isMobile ? "12px" : "14px"}>
                                {getFollowersText(followers)}
                            </Text>
                        </Box>
                    </Box>
                    <Box customCss={stylesRightBox} display="flex" flexDirection="column" height="100%"
                         justifyContent="space-between"
                         alignItems="end">
                        <Chip customCss={customChip}>
                            <StreamerIndicatorOnline isOnline={isOnline}/>
                            <Text fontSize={isMobile ? "9px" : "13px"} fontWeight="600"
                                  lineHeight="13px">{isOnline ? "Онлайн" : "Оффлайн"}</Text>
                        </Chip>
                        {!isMobile && <Box customCss={stylesContactBox} display="flex" gap="8px">
                            <ContactsBlock size="24px" contacts={contacts}/>
                        </Box>}
                    </Box>
                </ContentWrapper>
            </StreamerDetailBannerWrapper>
            {isMobile && <Box customCss={stylesContactBox} display="flex" gap="8px">
                <ContactsBlock size="24px" contacts={contacts}/>
            </Box>}
        </>
    );
};

export default StreamerBanner;
