import {IMenuItem} from "@common/types/interfaces/IMenuItem";

export const menuList: IMenuItem[] = [
    {
        id: 0,
        name: "Главная",
        link: "/"
    },
    {
        id: 1,
        name: "О нас",
        link: "/about"
    },
    {
        id: 2,
        name: "В онлайне",
        link: "/online"
    },
    {
        id: 3,
        name: "Состав",
        link: "/streamers"
    },
    {
        id: 4,
        name: "Вступить",
        link: "/join"
    }
];