/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';

interface BoxProps {
    padding?: string;
    margin?: string;
    backgroundColor?: string;
    color?: string;
    display?: string;
    flexDirection?: string;
    justifyContent?: string;
    alignItems?: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    gap?: string;
    customCss?: SerializedStyles;
}

const Box = styled.div<BoxProps>(
    ({
         padding = '0',
         margin = '0',
         backgroundColor = 'transparent',
         color = 'inherit',
         display = 'block',
         flexDirection = 'row',
         justifyContent = 'flex-start',
         alignItems = 'flex-start',
         width = 'auto',
         height = 'auto',
         borderRadius = '0',
         gap,
         customCss,
     }) => css`
        padding: ${padding};
        margin: ${margin};
        background-color: ${backgroundColor};
        color: ${color};
        display: ${display};
        flex-direction: ${flexDirection};
        justify-content: ${justifyContent};
        align-items: ${alignItems};
        width: ${width};
        height: ${height};
        border-radius: ${borderRadius};
        ${gap && `gap: ${gap};`}
        ${customCss};
    `
);

export default Box;
