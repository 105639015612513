/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Box from "@common/components/Box";
import {Streamer} from "@modules/streamers/types/models/streamer.model";
import Skeleton from "@common/components/Skeleton";
import ErrorData from "@common/components/ErrorData";
import StreamersCard from "@modules/streamers/components/StreamersCard";

interface StreamersListGrid {
    streamers?: Streamer[];
    isLoading?: boolean;
    error?: Error | null;
}

const StreamersListGrid: React.FC<StreamersListGrid> = ({streamers, isLoading, error}) => {
    const stylesBox = css`
        display: ${error ? "flex" : "grid"};
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 16px;
        grid-auto-rows: minmax(auto, max-content);

        @media (max-width: 464px) {
            grid-template-columns: repeat(1, 1fr);
        }
        @media (min-width: 464px) and (max-width: 690px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: 690px) and (max-width: 910px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: 910px) and (max-width: 1250px) {
            grid-template-columns: repeat(4, 1fr);
        }
    `;

    const renderContent = () => {
        if (isLoading) {
            return Array.from({length: 8}).map((_, idx) => <Skeleton height="280px" width="100%"/>)
        }

        if (!streamers || error) {
            return <ErrorData/>;
        }

        return streamers.map((streamer, idx) => <StreamersCard index={idx} key={`streamer-card-${streamer.login}`}
                                                               name={streamer.name} login={streamer.login}
                                                               followers={streamer.followers} avatar={streamer.avatar}
                                                               contacts={streamer.contacts}/>)
    };

    return (
        <Box customCss={stylesBox} width="100%" height="100%">
            {renderContent()}
        </Box>
    );
};

export default StreamersListGrid;
