import React, {ReactNode} from "react";

import styled from "@emotion/styled";
import {css, SerializedStyles} from "@emotion/react";

import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

interface SkeletonProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    children?: ReactNode;
    customCss?: SerializedStyles;
}

const SkeletonWrapper = styled.div<SkeletonProps>`
  ${({width, height, borderRadius, customCss}) => css`
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: ${width || '100%'};
    height: ${height || 'auto'};
    border-radius: ${borderRadius || '16px'};
    ${customCss};

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -150%;
      width: 150%;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
      animation: skeleton-loading .6s infinite;
    }
  `}
`;

const Skeleton: React.FC<SkeletonProps> = ({width, height, borderRadius, children, customCss}) => {
    const theme = useTheme().theme as Themes;

    const stylesSkeleton = css`
      background-color: ${theme.colors.card};
      ${customCss};
    `;

    return (
        <SkeletonWrapper customCss={stylesSkeleton} width={width} height={height} borderRadius={borderRadius}>
            {children}
        </SkeletonWrapper>
    )
};

export default Skeleton;