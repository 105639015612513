/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from "react";
import { useTheme } from "@styles/theme/ThemeContext";
import { Themes } from "@styles/theme/themes";
import useMediaQuery from "@common/hooks/useMediaQuery";
import { mediaQueries } from "@styles/media";
import Card from "@common/components/Card";
import CategoryContainer from "@common/components/CategoryContainer/CategoryContainer";
import Box from "@common/components/Box";
import { css } from "@emotion/react";
import Skeleton from "@common/components/Skeleton";
import {keepPreviousData, useQuery, useQueryClient} from "@tanstack/react-query";
import { TwitchClip } from "@modules/streamers/types/models/twitchClip.model";
import { fetchStreamerClips } from "@modules/streamers/services/fetchStreamerClips";
import StreamerClipsVideo from "@modules/streamers/components/StreamerClipsBlock/StreamerClipsVideo";
import Button from "@common/components/Button";
import Text from "@common/components/Text";
import {ITwitchClip} from "@modules/streamers/types/interfaces/ITwitchClip";
import {fetchStreamerVideos} from "@modules/streamers/services/fetchStreamerVideos";
import {TwitchVideo} from "@common/types/models/twitchVideo.model";
import Video from "@common/components/Video/Video";
import StreamerLastStreamVideo from "@modules/streamers/components/StreamerLastStreamBlock/StreamerLastStreamVideo";

interface StreamerLastStreamBlockProps {
    isLoading?: boolean;
    login: string | undefined;
}

const StreamerLastStreamBlock: React.FC<StreamerLastStreamBlockProps> = ({ isLoading, login = "" }) => {
    const theme = useTheme().theme as Themes;
    const isMobile = useMediaQuery(mediaQueries.mobile);
    const queryClient = useQueryClient();

    const {
        data: streamerVideos,
        isLoading: isLoadingStreamerClips,
        error: errorStreamerClips
    } = useQuery<TwitchVideo[], Error>({
        queryKey: [`streamer-video-${login}`],
        queryFn: () => fetchStreamerVideos(login),
    });

    const renderSkeleton = () => (
        <>
            {[...Array(4)].map((_, idx) => (
                <Skeleton key={idx} height="140px"/>
            ))}
        </>
    );

    const stylesGrid = css`
      display: grid;
      grid-template-columns: repeat(1, 100%);
      gap: 16px;
    `;

    return (
        <CategoryContainer title="Последние трансляции" sizeTitle="18px">
            <Card>
                <Box width="100%" customCss={stylesGrid}>
                    {streamerVideos?.map(video => <StreamerLastStreamVideo key={video.id} width="100%" thumbnail={video.thumbnail_url} video_id={video.id} view={video.view_count}
                                                         created={video.created_at} duration={video.duration}
                                                         information={{title: video.title, channel: video.user_name}}/>)}
                    {streamerVideos?.length === 0 && <Text fontSize="14px" fontWeight="600" textAlign="center">Последних трансляций нет :(</Text>}
                </Box>
            </Card>
        </CategoryContainer>
    );
};

export default StreamerLastStreamBlock;
