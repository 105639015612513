/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@styles/theme/ThemeContext';

interface TextProps {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    lineHeight?: string;
    textAlign?: string;
    customCss?: SerializedStyles;
    children: ReactNode;
}

const Text: React.FC<TextProps> = ({ color, fontSize, fontWeight, lineHeight, textAlign, customCss, children }) => {
    const { theme } = useTheme();

    const textStyles = css`
        color: ${color || theme.colors.text.primary}; 
        font-size: ${fontSize || '16px'}; 
        font-weight: ${fontWeight || 'normal'}; 
        line-height: ${lineHeight || 'normal'}; 
        text-align: ${textAlign || 'left'};
        ${customCss};
    `;

    const formatText = (text: string) => {
        const regex = /(\*.*?\*)|(_.*?_)|(~.*?~)/g;
        const parts = text.split(regex).filter(Boolean);

        return parts.map((part, index) => {
            if (part.startsWith('*') && part.endsWith('*')) {
                return <strong key={index}>{part.slice(1, -1)}</strong>;
            } else if (part.startsWith('_') && part.endsWith('_')) {
                return <em key={index}>{part.slice(1, -1)}</em>;
            } else if (part.startsWith('~') && part.endsWith('~')) {
                return <u key={index}>{part.slice(1, -1)}</u>;
            } else {
                return part;
            }
        });
    };

    const renderContent = (content: ReactNode) => {
        if (typeof content === 'string') {
            return formatText(content);
        }
        return content;
    };

    return (
        <span css={textStyles}>
            {Array.isArray(children)
                ? children.map((child, index) => renderContent(child))
                : renderContent(children)}
        </span>
    );
};

export default Text;
