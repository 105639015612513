/** @jsxImportSource @emotion/react */
import React, {useRef} from "react";
import styled from "@emotion/styled";
import CategoryContainer from "@common/components/CategoryContainer/CategoryContainer";
import Skeleton from "@common/components/Skeleton";
import Carousel from "@common/components/Carousel/Carousel";
import Text from "@common/components/Text";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import {StreamerBio} from "@modules/streamers/types/models/streamerBio.model";
import StreamerBioChip from "@modules/streamers/components/StreamerBioChip";
import Box from "@common/components/Box";
import {css} from "@emotion/react";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";

interface StreamerInfoBlockProps {
    isLoading?: boolean;
    description?: string;
    bioList?: StreamerBio[];
}

const StreamerInfoBlock: React.FC<StreamerInfoBlockProps> = ({isLoading, description, bioList = []}) => {
    const theme = useTheme().theme as Themes;
    const isMobile = useMediaQuery(mediaQueries.mobile);
    const containerRef = useRef<HTMLDivElement>(null);

    const stylesBioList = css`
      overflow-x: scroll;
      scrollbar-width: none;
    `;

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (containerRef.current) {
            if (event.deltaY > 0) {
                containerRef.current.scrollBy({left: 100, behavior: "smooth"})
            } else if (event.deltaY < 0) {
                containerRef.current.scrollBy({left: -100, behavior: "smooth"})
            }
        }
    };

    const renderSkeleton = () => (
        <>
            <Skeleton height="100px" width="100%"/>
            <Carousel width="100%" itemWidth="190px" disableButton>
                {Array.from({length: 6}).map((_, idx) => <Skeleton height="36px" width="100%" borderRadius="8px"/>)}
            </Carousel>
        </>
    );

    return (
        <CategoryContainer title="Информация" sizeTitle="18px">
            {isLoading ? renderSkeleton() :
                <>
                    <Text color={theme.colors.text.secondary} fontSize={isMobile ? "13px" : "15px"} fontWeight="300">{description}</Text>
                    <Box id="scrollable" ref={containerRef} customCss={stylesBioList} display="flex" gap="8px"
                         width="100%" onWheel={handleScroll}>
                        {bioList.map(bio => <StreamerBioChip type={bio.type} value={bio.value}/>)}
                    </Box>
                </>
            }
        </CategoryContainer>
    );
};

export default StreamerInfoBlock;