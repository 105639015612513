import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ModalContextType {
    isOpen: boolean;
    title?: string;
    content: ReactNode;
    openModal: (title?: string, content?: ReactNode) => void;
    closeModal: () => void;
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [content, setContent] = useState<ReactNode>(null);

    const openModal = (title?: string, content?: ReactNode) => {
        setTitle(title);
        setContent(content);
        setIsOpen(true);
    };

    const closeModal = () => setIsOpen(false);

    return (
        <ModalContext.Provider value={{ isOpen, title, content, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
};
