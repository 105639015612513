/** @jsxImportSource @emotion/react */
import React from "react";

import styled from "@emotion/styled";

import Box from "@common/components/Box";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import {mediaQueries} from "@styles/media";
import {HeaderLogo} from "@common/components/Header/HeaderLogo";
import {MenuButton} from "@common/components/Menu/MenuButton";
import {HeaderBackButton} from "@common/components/Header/HeaderBackButton";

interface HeaderProps {
    openMenu: () => void;
    backLink?: string;
}

const HeaderWrapper = styled(Box)<{ theme: Themes }>`
    position: fixed;
    top: 0;
    background-color: ${(props) => props.theme.colors.header};
    height: 70px;
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    z-index: 1000;
    
    ${mediaQueries.mobile} {
        height: 60px;
    }
`;

export const Header: React.FC<HeaderProps> = ({openMenu, backLink}) => {
    const theme = useTheme().theme as Themes;

    return (
        <HeaderWrapper theme={theme}>
            <Box display="flex" alignItems="center" gap="8px">
                {backLink && <HeaderBackButton backLink={backLink} size="36px"/>}
                <HeaderLogo homeLink="/"/>
            </Box>
            <Box display="flex" alignItems="center">
                <MenuButton action={openMenu}/>
            </Box>
        </HeaderWrapper>
    );
}