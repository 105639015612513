/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import PageContainer from "@common/components/PageContainer";
import PageTitle from "@common/components/PageTitle";
import { css } from "@emotion/react";
import Box from "@common/components/Box";
import Text from "@common/components/Text";
import Button from "@common/components/Button";
import Image from "@common/components/Image";
import logo from "@assets/img/slm.png";
import useMediaQuery from "@common/hooks/useMediaQuery";
import { mediaQueries } from "@styles/media";
import { fetchJoinInfo } from "@modules/join/services/fetchJoinInfo";

const inputStyle = css`
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #595959;
  border-radius: 8px;
  font-size: 16px;
  background: none;
  color: white;

  &:focus {
    border-color: #bbbbbb;
    outline: white;
  }
`;

const formContainerStyle = css`
  display: grid;
  gap: 16px;
  width: 100%;
  max-width: 800px;
`;

const alertStyle = css`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
`;

const JoinPage: React.FC = () => {
    const isMobile = useMediaQuery(mediaQueries.mobile);

    const [formData, setFormData] = useState({
        twitchLink: "",
        socialLink: "",
        nameAge: "",
        contactLink: "",
    });

    const [errors, setErrors] = useState({
        twitchLink: "",
        socialLink: "",
        nameAge: "",
        contactLink: "",
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [alert, setAlert] = useState<{ message: string; type: "success" | "error" } | null>(null);

    const validateForm = () => {
        const newErrors = {
            twitchLink: formData.twitchLink ? "" : "Поле обязательно",
            socialLink: formData.socialLink ? "" : "Поле обязательно",
            nameAge: formData.nameAge ? "" : "Поле обязательно",
            contactLink: formData.contactLink ? "" : "Поле обязательно",
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some((error) => error);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await fetchJoinInfo(formData);
                setIsSubmitted(true);
                setAlert({ message: "Заявка успешно отправлена!", type: "success" });
            } catch (error) {
                setAlert({ message: "Произошла ошибка при отправке. Попробуйте еще раз.", type: "error" });
            }
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear error message when the user starts typing
        if (value) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        }
    };

    // Automatically hide the alert after 5 seconds
    useEffect(() => {
        if (alert) {
            const timer = setTimeout(() => setAlert(null), 3000);
            return () => clearTimeout(timer);
        }
    }, [alert]);

    return (
        <PageContainer>
            <PageTitle name="Вступить" />
            <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
                <Text
                    textAlign="center"
                    fontSize={isMobile ? "16px" : "18px"}
                    css={css`margin-bottom: 16px;`}
                >
                    Если ты хочешь стать частью SLM комьюнити и развиваться в мире стриминга — у нас для тебя отличная новость!
                    Мы ищем ярких, активных и креативных стримеров, готовых показать себя и покорить Twitch.
                </Text>
                <Image src={logo} alt="logo SLM" width={isMobile ? "320px" : "480px"} height={isMobile ? "180px" : "270"} />
            </Box>

            <Box width="100%" height="100%" display="flex" justifyContent="center" customCss={css`margin-top: 32px;`}>
                <form onSubmit={handleSubmit} css={css`width: 100%; max-width: 800px; padding: 20px;`}>
                    <Box css={formContainerStyle} customCss={css`grid-template-columns: 1fr ${!isMobile && "1fr"};`}>
                        <label>
                            <Text fontSize="16px">Ссылка на Twitch</Text>
                            <input
                                css={inputStyle}
                                type="url"
                                name="twitchLink"
                                value={formData.twitchLink}
                                onChange={handleChange}
                            />
                            {errors.twitchLink && <Text color="red" fontSize="12px">{errors.twitchLink}</Text>}
                        </label>
                        <label>
                            <Text fontSize="16px">Ссылка на соц. сеть (TG, VK, Discord)</Text>
                            <input
                                css={inputStyle}
                                type="url"
                                name="socialLink"
                                value={formData.socialLink}
                                onChange={handleChange}
                            />
                            {errors.socialLink && <Text color="red" fontSize="12px">{errors.socialLink}</Text>}
                        </label>
                        <label>
                            <Text fontSize="16px">Имя и возраст</Text>
                            <input
                                css={inputStyle}
                                type="text"
                                name="nameAge"
                                value={formData.nameAge}
                                onChange={handleChange}
                            />
                            {errors.nameAge && <Text color="red" fontSize="12px">{errors.nameAge}</Text>}
                        </label>
                        <label>
                            <Text fontSize="16px">Ссылка на личный Telegram</Text>
                            <input
                                css={inputStyle}
                                type="url"
                                name="contactLink"
                                value={formData.contactLink}
                                onChange={handleChange}
                            />
                            {errors.contactLink && <Text color="red" fontSize="12px">{errors.contactLink}</Text>}
                        </label>
                    </Box>
                    <Button
                        customCss={css`
              margin-top: 24px;
              width: 100%;
              padding: 12px;
              font-size: 16px;
              font-weight: 600;
              border: none;
              border-radius: 8px;
              margin-bottom: 4px;
            `}
                        type="submit"
                    >
                        Отправить заявку
                    </Button>
                    <Text fontSize="12px" fontWeight="400">
                        Нажимая на кнопку, вы подтверждаете, что соответствуете <a
                        href="https://telegra.ph/Kriterii-priyoma-v-SLM-SQUAD-05-21"
                        target="_blank"
                        style={{ textDecoration: "none", color: "#91f85c" }}
                    >
                        требованиям
                    </a> для вступления в сквад.
                    </Text>
                </form>
            </Box>

            {alert && (
                <div
                    css={[
                        alertStyle,
                        { backgroundColor: alert.type === "success" ? "#4CAF50" : "#F44336" }
                    ]}
                >
                    {alert.message}
                </div>
            )}
        </PageContainer>
    );
};

export default JoinPage;
