/** @jsxImportSource @emotion/react */
import React from 'react';

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import {css} from "@emotion/react";

interface ListItemProps {
    name: string;
    value: string;
}

const ListItem: React.FC<ListItemProps> = ({name, value}) => {
    const theme = useTheme().theme as Themes;

    const stylesTitle = css`
        max-width: 60%;
    `;

    return (
        <Box display="flex" justifyContent="space-between" width="100%">
            <Text customCss={stylesTitle} fontSize="14px" fontWeight="600" color={theme.colors.text.secondary}>{name}:</Text>
            <Text fontSize="14px" color={theme.colors.text.subtitle}>{value}</Text>
        </Box>
    );
};

export default ListItem;
