/** @jsxImportSource @emotion/react */
import React from 'react';

import {css} from "@emotion/react";

import Text from "@common/components/Text";
import {mediaQueries} from "@styles/media";

const MainLogoTitle: React.FC = () => {
    const stylesText = css`
        ${mediaQueries.mobile} {
            font-size: 48px;
        }
    `;

    return (
        <Text customCss={stylesText} fontSize="68px" fontWeight="900" lineHeight="1">
            SLM
            <br/>
            SQUAD
        </Text>
    );
};

export default MainLogoTitle;
