/** @jsxImportSource @emotion/react */
import React from 'react';

import Box from "@common/components/Box";
import Text from "@common/components/Text";
import {IList} from "@common/types/interfaces/IList";
import ListItem from "@common/components/List/ListItem";
import {css} from "@emotion/react";
import {mediaQueries} from "@styles/media";
import {getRandomWord} from "@common/services/getRandomWord";

interface ListProps {
    list: IList[];
    width?: string;
}

const List: React.FC<ListProps> = ({list, width}) => {
    const stylesList = css`
        ${mediaQueries.mobileAndTablet} {
            width: 100%
        }
    `;

    return (
        <Box customCss={stylesList} display="flex" flexDirection="column" width={width} gap="8px">
            {list.map((item) => <ListItem key={`list-item-${getRandomWord(8)}`} name={item.name} value={item.value}/>)}
        </Box>
    );
};

export default List;
