/** @jsxImportSource @emotion/react */
import React, {useState} from "react";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";
import useMediaQuery from "@common/hooks/useMediaQuery";
import {mediaQueries} from "@styles/media";
import Box from "@common/components/Box";
import {StatisticGame} from "@modules/streamers/types/models/statisticGame.model";
import {css} from "@emotion/react";
import Image from "@common/components/Image";
import Text from "@common/components/Text";
import Button from "@common/components/Button";

interface StreamerGamesTableProps {
    data?: StatisticGame[];
}

const StreamerGamesTable: React.FC<StreamerGamesTableProps> = ({data}) => {
    const theme = useTheme().theme as Themes;
    const isMobile = useMediaQuery(mediaQueries.mobile);
    const [isShowMore, setIsShowMore] = useState<boolean>(false);

    const maxWatchedHours = data?.[0].streamed_hours || 100;
    const maxAvgViewers = data?.reduce((max, value) => Math.max(max, value.avg_viewers), -Infinity) || 100;
    const maxPeekViewers = data?.reduce((max, value) => Math.max(max, value.peek_viewers), -Infinity) || 100;

    const styledHeader = css`
      box-sizing: border-box;
      background: rgba(80, 80, 80, 0.45);
      padding: ${isMobile ? "4px" : "8px"};
      border-radius: 8px;
      font-weight: 500;
      align-items: center;
      font-size: ${isMobile ? "12px" : "14px"};
    `;

    const styledProgress = css`
      height: 8px;
      background: #7a7a7a;
      width: 100%;
      border-radius: 8px;
    `;

    const styledWatchedProgress = css`
      height: 8px;
      background: #fc3636;
      border-radius: 8px;
    `;

    const styledAvgProgress = css`
      height: 8px;
      background: #18BC9C;
      border-radius: 8px;
    `;

    const styledMaxPeekProgress = css`
      height: 8px;
      background: #bcb01b;
      border-radius: 8px;
    `;

    const handleShowMore = () => {
        setIsShowMore(prevState => !prevState);
    };

    return data ? (
        <Box display="flex" width="100%" flexDirection="column" gap={isMobile ? "8px" : "16px"}>
            <Box css={styledHeader} display="flex" width="100%" gap={isMobile ? "4px" : "16px"}>
                <Box width="5%">#</Box>
                <Box width={isMobile ? "35%" : "45%"}>Категория</Box>
                <Box width={isMobile ? "60%" : "52%"} display="flex" gap={isMobile ? "4px" : "16px"}>
                    <Box width="33%">Часов стримов</Box>
                    <Box width="33%">Сред. онлайн</Box>
                    <Box width="33%">Макс. зрителей</Box>
                </Box>
            </Box>
            <Box display="flex" width="100%" flexDirection="column" gap="8px">
                {data.map((game, idx) => (isShowMore || idx < 3) && (
                    <Box key={idx} display="flex" width="100%" padding={isMobile ? "4px" : "8px"}
                         gap={isMobile ? "4px" : "16px"}>
                        <Box style={{alignSelf: "center"}} width="5%" display="flex" alignItems="center">
                            <Text fontWeight="500" fontSize={isMobile ? "10px" : "14px"}>{idx + 1}</Text>
                        </Box>
                        <Box width={isMobile ? "35%" : "45%"} display="flex" gap="8px" alignItems="center">
                            <Image height={isMobile ? "36px" : "72px"} width={isMobile ? "27px" : "54px"}
                                   src={game.image} alt={game.name}/>
                            <Text fontSize={isMobile ? "10px" : "16px"} fontWeight="600">{game.name}</Text>
                        </Box>
                        <Box width={isMobile ? "60%" : "52%"} display="flex" gap={isMobile ? "4px" : "16px"}>
                            <Box width="33%" display="flex" flexDirection="column" gap="2px">
                                <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                                    <Text fontWeight="400"
                                          fontSize={isMobile ? "10px" : "14px"}>{game.streamed_hours}</Text>
                                    <Text fontWeight="300" fontSize="10px">{game.time_share}%</Text>
                                </Box>
                                <Box css={styledProgress}>
                                    <Box width={`${(game.streamed_hours * 100) / maxWatchedHours}%`}
                                         css={styledWatchedProgress}/>
                                </Box>
                            </Box>
                            <Box width="33%">
                                <Box display="flex" justifyContent="space-between" width="100%">
                                    <Text fontWeight="400"
                                          fontSize={isMobile ? "10px" : "14px"}>{game.avg_viewers}</Text>
                                </Box>
                                <Box css={styledProgress}>
                                    <Box width={`${(game.avg_viewers * 100) / maxAvgViewers}%`}
                                         css={styledAvgProgress}/>
                                </Box>
                            </Box>
                            <Box width="33%">
                                <Box display="flex" justifyContent="space-between" width="100%">
                                    <Text fontWeight="400"
                                          fontSize={isMobile ? "10px" : "14px"}>{game.peek_viewers}</Text>
                                </Box>
                                <Box css={styledProgress}>
                                    <Box width={`${(game.peek_viewers * 100) / maxPeekViewers}%`}
                                         css={styledMaxPeekProgress}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Button width="100%" padding="6px" backgroundColor="rgba(80, 80, 80, 0.45)" onClick={handleShowMore}>
                <Text color={theme.colors.text.secondary}>{isShowMore ? "Скрыть" : "Показать все"}</Text>
            </Button>
        </Box>
    ) : null;
};

export default StreamerGamesTable;
