/** @jsxImportSource @emotion/react */
import React, {useEffect} from "react";

import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {useNavigate} from "react-router-dom";

import {mediaQueries} from "@styles/media";
import {useTheme} from "@styles/theme/ThemeContext";
import {Themes} from "@styles/theme/themes";

interface MenuLinkProps {
    id?: number;
    content?: string;
    active?: boolean;
    link?: string;
    isShow?: boolean;
}

const MenuLinkWrapper = styled.li`
    transform-style: preserve-3d;
    font-size: 55px;
    font-weight: 900;
    cursor: pointer;
`;

export const MenuLink: React.FC<MenuLinkProps> = ({id = 0, link = "/", content, active, isShow}) => {
    const [initialActive, setInitialActive] = React.useState(false);
    const navigate = useNavigate();
    const theme = useTheme().theme as Themes;

    const handlerNavigate = () => navigate(link);

    const stylesMenuLink = css`
        transform: ${initialActive ? 'translateX(0) translateZ(0)' : 'translateX(350px) translateZ(-1000px)'};
        opacity: ${initialActive ? 1 : 0};
        transition: ${initialActive ? 'transform .4s,opacity .4s' : 'transform .08s,opacity .08s'};
        transition-delay: ${id > 0 ? .04 * id : 0}s;
        color: ${theme.colors.text.primary};

        ${active && `
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -25%);
                width: 110%;
                height: 15px;
                background-color: ${theme.colors.button.primary};
            }
        `}
        ${mediaQueries.mobile} {
            font-size: 28px;

            ${active && `
                &:before {
                    width: 110%;
                    height: 10px;
                }
            `}
        }

        ${mediaQueries.tablet} {
            font-size: 42px;

            ${active && `
                &:before {
                    width: 110%;
                    height: 12px;
                }
            `}
        }
    `;

    useEffect(() => {
        if (!initialActive) {
            const timer = setTimeout(() => {
                setInitialActive(true);
            }, 10);
            return () => clearTimeout(timer);
        }
    }, [isShow]);

    return (
        <MenuLinkWrapper className="click_block" css={stylesMenuLink} onClick={handlerNavigate}>
            {content}
        </MenuLinkWrapper>
    );
}